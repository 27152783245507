/**
 * Defintion for any kind of message used by the HostCommunicationService
 */
export abstract class Message<Type> {

  /**
   * Type of message should be defined by any kind of message
   */
  abstract type: Type;

  /**
   * Optional: each message could contain some data
   */
  data?: any;

  constructor(data: any = null) {
    this.data = data;
  }
}
