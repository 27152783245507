<div>
    <apx-chart
        [series]="series"
        [chart]="chart"
        [xaxis]="xaxis"
        [yaxis]="yaxis"
        [fill]="fill"
        [legend]="legend"
        [plotOptions]="plotOptions"
        [dataLabels]="dataLabels"
        [markers]="markers"
        [labels]="labels"
        [tooltip]="tooltip"
        [states]="states"
        [annotations]="annotations"
    >
    </apx-chart>
</div>