import { Component, Input } from '@angular/core';
import { DashboardResult } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-label-evolution',
  templateUrl: './label-evolution.component.html',
  styleUrls: ['./label-evolution.component.scss']
})
export class LabelEvolutionComponent {

  @Input("result") result!: DashboardResult;

  constructor() {}
}
