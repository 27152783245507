export enum COLORS {
    WHITE =                 'FFFFFF',
    BLACK =                 '#000000',
    LL_WHITE =              '#FCFCFC',
    LL_AQUA_DEEP =          '#245B60',
    LL_AQUA_SHALLOW =       '#2C6064',
    LL_AQUA_LIGHT =         '#396B6F',
    LL_AQUA_GREY =          '#6F9498',
    LL_GOLD =               '#CEBA8F',
    LL_RISK_YELLOW =        '#E5B411',
    LL_RISK_GREEN_LIGHT =   '#a1c567',
    LL_RISK_GREEN =         '#33D65C',
    LL_RISK_ORANGE =        '#D2754A',
    LL_RISK_RED =           '#FF6060',
    LL_DODGER =             '#1887FD',
    LL_GREY =               '#B2B2B2'
}
