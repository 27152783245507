import { LanguageTranslations } from "../model/Translate";

export const calculateAge = (dobInMilliseconds: number): number => {
    const currentDate = new Date();
    const birthDate = new Date(dobInMilliseconds);
  
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
  
    return age;
}



export const translate = (currentLang: string, languageTranslations: LanguageTranslations): string  => {
    switch(currentLang) {
        case 'fr':
            return languageTranslations.fr;
        case 'en':
            return languageTranslations.en;
        default:
            return languageTranslations.nl;
    }
};

export const isElementVisibleInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
   
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;
}