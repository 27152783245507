import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe to filter the first N items of an array 
 */
@Pipe({
    name: 'limitItems'
})
export class LimitItemsPipe implements PipeTransform {

    /**
     * filter the first N items of an array 
     * @param values to filter
     * @param count first number of items to fitler
     * @returns the fitlered array
     */
    transform(values: any[] | null | undefined, count: number): any[] | null | undefined {
        if (values) {
            return values.slice(0, count);
        }
        return values;
    }
}