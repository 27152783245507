import { Observable, map } from "rxjs";
import { DashboardResultGroup } from "../model/DashboardResult";
import { DataService } from "../services/data/data.service";
import { GroupService } from "../services/group-service/group.service";

type DashboardDataResult = {
  categoryGroup: DashboardResultGroup | null;
  selectedGroup: DashboardResultGroup | null;
}

export const getDashboardData = (
    dataService: DataService,
    groupService: GroupService,
    category: string | null
  ): Observable<DashboardDataResult> => {
    return dataService.dashboardData.pipe(
      map((d: DashboardResultGroup | null) => {
        let categoryGroup: DashboardResultGroup | null = null;
        let selectedGroup: DashboardResultGroup | null = null;

        if (d?.groups) {
          categoryGroup = d.groups.find((grp: DashboardResultGroup) => {
            return grp?.tag == `DASHBOARD_${category?.toUpperCase()}`;
          }) ?? null;
  
          groupService.selectedGroup.pipe(
            map(grp => {
              selectedGroup = grp ?? categoryGroup?.groups[0] ?? null;
            })
          ).subscribe();
        }

        return {
          categoryGroup,
          selectedGroup
        };
      })
    );
  }