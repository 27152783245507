<div class="ll-row-ctr">
    <p>{{ result.name }}:</p>
    <p [style.color]="latestValue | extractResultColor"><ll-value-debugger [result]="result"></ll-value-debugger> {{ latestValue | extractResultValue }}</p>
</div>
<apx-chart
    *ngIf="xaxis && yaxis"
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [stroke]="stroke"
    [fill]="fill"
    [markers]="markers"
    [legend]="legend"
    [grid]="grid"
    [tooltip]="tooltip"
    [annotations]="annotations"
>
</apx-chart>