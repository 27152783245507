<div class="score" *ngIf="score">
    <div class="ll-row-ctr ll-space-between">
        <span>{{ score.name }}</span>
        <span>{{ score.value }} {{ score.valueUnit }}</span>
    </div>
    <div #gradient class="gradient"
        [class.reverse]="score.reverseGradient"
        [class.noscore]="!score.score"
        *ngIf="score.percentage"
    >
        <div class="label" #label>
            <ll-label *ngIf="score.score" [bgColor]="this.score.labelColor ?? COLORS.BLACK" [size]="9"><ll-value-debugger [result]="result"></ll-value-debugger><span class="label-value">{{ score.score }} {{ score.scoreUnit }}</span></ll-label>
            <div class="ll-triangle-bottom" #triangle></div>
        </div>
    </div>
</div>