import { NgModule } from "@angular/core";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [],
    imports: [
        MatIconModule,
        MatDialogModule
    ],
    exports: [
        MatIconModule,
        MatDialogModule
    ]
})
export class MaterialModule {
  // Load custom icons to use with Material Icons
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    Object.keys(ICONS).forEach((key: string) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(ICONS[key]));
    });
  }
}

// Custom icon definition (to use with MatIconModule)
const ICONS: any = {
//   ll_icon_home:       '../assets/icons/material/home.svg',
};
