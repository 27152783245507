import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENCODED_DUMMY_DATA } from './dummy-data';
import { ClientMessage, ClientMessageType } from './model/ClientMessage';
import { HostMessage, HostMessageType, HostType } from './model/HostMessage';
import { HostUtil } from 'src/app/utility/host';

/**
 * A service responsible for communication between this dashboard and the host (iOS/Android webview) running it.
 */
@Injectable({
  providedIn: 'root'
})
export class HostCommunicationService {

  /**
   * Observables to dispatch messages (= events) from host (iOS or Android app)
   */
  private _hostMessage: BehaviorSubject<HostMessage | null> = new BehaviorSubject<HostMessage | null>(null);
  public hostMessage: Observable<HostMessage | null> = this._hostMessage.asObservable();

  constructor(private zone: NgZone) {
    // Add event listener on window to listen for messages (run this code within ngZone to sync with angular lifecycle)
    zone.run(() => {
      window.addEventListener("message", (hostMessage: MessageEvent<any>) => {
        // Get data from message
        let json = hostMessage.data
        // Check if json data is a string => Should be, since we expect the host to send data as json strings
        if (typeof json === 'string' || json instanceof String) {
          let msg = JSON.parse(hostMessage.data) as HostMessage;
          // If message has a type and host we assume it is a valid message -> make the message available for observers
          if (msg.type && msg.host) {
            // HostMessages of type data has Base64 encoded JSON string in data field -> decode first
            if (msg.type == HostMessageType.DATA) {
              let decoded = decodeURIComponent(atob(msg.data));
              msg.data = JSON.parse(decoded);
            }
            // DEBUG: Uncomment line below to see the dashboard data in the console.
            // console.log(JSON.stringify(msg));
            this._hostMessage.next(msg);
          }
        }
      });
      // When the event listener is attached we send a message from the client to the host: notify the host we are ready to receive messages
      this.sendEvent(new ClientMessage(ClientMessageType.READY));
      
      // To avoid manual message posting at every refresh when developping we can send fake a host message post by activating the 'populateDataAtInit'
      // flag in the environment file. Make sure the ./dummy-data.ts file is filled in.
      if (environment.populateDataAtInit) {
        (<any>window).postMessage(JSON.stringify(new HostMessage(
          HostMessageType.DATA,
          HostType.ANDROID,
          ENCODED_DUMMY_DATA
        )));
      }
    });
  }

  /**
   * Send a message from the dashboard to the host (iOS/Android) running the dashboard.
   * @param message to send
   */
  public sendEvent(message: ClientMessage) {
    if (HostUtil.isIOS()) {
      // Use the interface provided by WKWebView from iOS (iosEventListener is defined by our custom code in swift)
      (<any>window).webkit.messageHandlers.iosEventlistener.postMessage(message);
    }
    else if (HostUtil.isAndroid()) {
      // Use the interface provided by WebViewClient from Android (androidEventListener is defined by our custom code in kotlin)
      (<any>window).androidEventlistener.postMessage(JSON.stringify(message));
    }
    else if (parent) {
      // Only do post when window is nested
      if (parent != window) {
        parent.postMessage(JSON.stringify(message), environment.expertAppOrigin);
      }
    }
  }
}