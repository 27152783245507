import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { COLORS } from 'src/app/constants/Colors';

@Component({
  selector: 'll-risk-level-circle',
  templateUrl: './risk-level-circle.component.html',
  styleUrls: ['./risk-level-circle.component.scss']
})
export class RiskLevelCircleComponent implements AfterViewInit {

  @Input('color') color!: COLORS;

  @ViewChild('circle', { static: false }) circle!: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.circle.nativeElement, 'background-color', this.color);
  }

}
