import { Routes } from '@angular/router';
import { AgeAndScoreEvolutionPageComponent } from 'src/app/pages/age-and-score-evolution-page/age-and-score-evolution-page.component';
import { CategoryPageComponent } from 'src/app/pages/category-page/category-page.component';
import { RawDataPageComponent } from 'src/app/pages/raw-data-page/raw-data-page.component';
import { SecondDisplayPageComponent } from 'src/app/pages/second-display-page/second-display-page.component';
import { SummaryPageComponent } from 'src/app/pages/summary-page/summary-page.component';
import { APP_ROUTES } from './AppRoutes';
import { InformationPageComponent } from 'src/app/pages/information-page/information-page.component';

/**
 * App Routes config
 */
export const appRouterConfig: Routes = [
    {
        path: '',
        redirectTo: APP_ROUTES['SUMMARY'].value,
        pathMatch: 'full'
    },
    {
        path: APP_ROUTES['SUMMARY'].value,
        component: SummaryPageComponent
    },
    {
        path: APP_ROUTES['SCORE_EVOLUTION'].value,
        component: AgeAndScoreEvolutionPageComponent
    },
    {
        path: APP_ROUTES['AGE_EVOLUTION'].value,
        component: AgeAndScoreEvolutionPageComponent
    },
    {
        path: `${APP_ROUTES['RAW_DATA'].value}/:${APP_ROUTES['RAW_DATA']['params'][0]}`,
        component: RawDataPageComponent
    },
    {
        path: `${APP_ROUTES['CATEGORY'].value}/:${APP_ROUTES['CATEGORY']['params'][0]}`,
        component: CategoryPageComponent
    },
    {
        path: `${APP_ROUTES['SECOND_DISPLAY'].value}`,
        component: SecondDisplayPageComponent
    },
    {
        path: `${APP_ROUTES['INFORMATION'].value}/:${APP_ROUTES['INFORMATION']['params'][0]}/:${APP_ROUTES['INFORMATION']['params'][1]}/:${APP_ROUTES['INFORMATION']['params'][2]}`,
        component: InformationPageComponent
    },
    {
        path: '**',
        redirectTo: '/' + APP_ROUTES['SUMMARY'].value
    }
];
