import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ChartItemType } from 'src/app/model/Chart';

@Component({
  selector: 'll-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent extends BaseComponent implements OnInit {
  @Input('items') items!: ChartItemType[];
  @Input('activeItem') activeItem: ChartItemType | null = null;
  @Input('title') title: string | null = null;

  public itemsValueTotal!: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.itemsValueTotal = this.items.reduce((sum, current) => sum + current.value,  0);
  }
}
