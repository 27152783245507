import { NgModule, InjectionToken} from '@angular/core';
import { LangChangeEvent, TranslateModule as NgxTranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
const packageJson = require('../../../../package.json');

/**
 * Module responsible for translations, uses ngx-translate
 */
@NgModule({
  declarations: [],
  exports: [
    NgxTranslateModule
  ]
})
export class TranslationModule {}

// Injection token for the configuration of a httpTranslationLoaderFactory function
export const TRANSLATION_LOADER_FOR_MODULE = new InjectionToken<string>('httpLoaderFactoryModule');

// Factory to create an http translation loader, needs the HttpClient and optional lazy loaded module name
export const httpTranslationLoaderFactory = (http: HttpClient) => {
  const version: string = packageJson.version;
  return new TranslateHttpLoader(http, `./assets/i18n/`, `.json?v=${version}`);
}

export class TranslatedModule {

  constructor(protected topLevelTranslateService: TranslateService, protected moduleTranslateService: TranslateService) {
    this.getTranslations(topLevelTranslateService.currentLang);
    topLevelTranslateService.onLangChange.subscribe((change: LangChangeEvent) => {
      this.getTranslations(change.lang)
    });
  }

  private getTranslations(lang: string) {
    this.moduleTranslateService.getTranslation(lang).subscribe((translation) => {
      this.topLevelTranslateService.setTranslation(lang, translation, true);
    });
  }
}