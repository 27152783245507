import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardResult, DashboardResultGroup, DashboardResultRiskLevel, DashboardResultValue } from '../../model/DashboardResult';
import { ResultEvolutionDialogComponent } from './result-evolution-dialog/evolution-dialog.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/modules/routing/AppRoutes';

@Directive({
    selector: '[llResultEvolution]',
})
export class ResultEvolutionDirective {

    @Input('llResultEvolutionType') type: string | null = null;
    @Input('llResultEvolutionGroup') group: DashboardResultGroup | null = null;
    @Input('llResultEvolutionResult') result: DashboardResult | null = null;

    constructor(
        private router: Router,
        private dialog: MatDialog,
    ) {}

    @HostListener('click', ['$event'])
    onClick($event: PointerEvent) {
        // Check if result is present
        if (this.group && this.result && this.result.values.length > 0) {
            // Get the latest value from result
            let latestValue = DashboardResult.getLatestValue(this.result);
            // Validate the latest result exists
            if (latestValue && latestValue.riskLevel  != DashboardResultRiskLevel.NOT_AVAILABLE) {            
                if (environment.informationPageEnabled) {
                    this.router.navigate(['/', APP_ROUTES['INFORMATION'].value, this.type, this.group?.tag, this.result.tag]);
                } else {
                    this.dialog.open(ResultEvolutionDialogComponent, {
                        data: this.result,
                        width: DashboardResultValue.isNumeric(latestValue) ? '100%' : '60%',
                        height: '70%',
                        panelClass: 'evolution-dialog'
                    });
                }
            }
        }
    }
}
  