import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecondDeviceDataLink } from 'src/app/model/SecondDeviceDataLink';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient) { }

  //#region SECOND DISPLAY CALLS

  /**
   * Start a session to visualize a second display session.
   * @param uuid to initiate session (generate on client side) -> this 
   * @returns a sessionId (this is also a uuid)
   */
  public startSecondDisplaySession(uuid: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('deviceId', uuid);
    return this.http.post<string>(`${environment.apiBase}/users/device/display`, null, { params });
  }

  /**
   * Get session status
   * @param sessionId the id received from backend
   * @param deviceId the id created to start the session
   * @returns observable status
   */
  public getSecondDisplaySessionStatus(sessionId: string, deviceId: string): Observable<SecondDeviceDataLink> {
    let params = new HttpParams();
    params = params.append('deviceId', deviceId);
    return this.http.get<SecondDeviceDataLink>(`${environment.apiBase}/users/device/display/${sessionId}/status`, { params });
  }

  /**
   * Get dashboard data from signed link
   * @param link signed link
   * @returns observable dashboard data
   */
  public getDashboardDataForSecondDisplay(link: string): Observable<any> {
    return this.http.get<any>(link);
  }

  //#endregion
}
