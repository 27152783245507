import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResultGroup, DashboardResultRiskLevel } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';
import { GroupService } from 'src/app/services/group-service/group.service';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';
import { getDashboardData } from 'src/app/utility/dataHelpers';

@Component({
  selector: 'll-raw-data-page',
  templateUrl: './raw-data-page.component.html',
  styleUrls: [
    './../layout.scss',
    './raw-data-page.component.scss'
  ]
})
export class RawDataPageComponent extends BaseComponent implements OnInit {

  category: string | null = null;
  categoryGroup: DashboardResultGroup | null = null;
  selectedGroup: DashboardResultGroup | null = null;
  RiskLevel = DashboardResultRiskLevel;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private analytics: AnalyticsService,
    private groupService: GroupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.route.params.subscribe((params) => {
      this.category = params[this.APP_ROUTES['RAW_DATA']['params'][0]];
      this.analytics.sendEvent(new Event(
        'open-dashboard-data',
        'view-results',
        'dashboard',
        new Map([
          ['type', this.category ?? 'N/A']
        ])
      ));
      
      getDashboardData(this.dataService, this.groupService, this.category).subscribe((dashboardDataResult) => {
        this.categoryGroup = dashboardDataResult.categoryGroup;
        this.selectedGroup = dashboardDataResult.selectedGroup;
      }); 
    }));
  }

  public selectGroup(group: DashboardResultGroup): void {
    this.selectedGroup = group;
    this.groupService.selectGroup(group);

    this.analytics.sendEvent(new Event(
      'open-dashboard-data-grp',
      'view-results',
      'dashboard',
      new Map([
        ['type', this.category ?? 'N/A'],
        ['group', group.tag.toLowerCase()]
      ])
    ));
  }
}
