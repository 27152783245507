import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { DashboardDataStatus, DashboardResultGroup } from 'src/app/model/DashboardResult';
import { HostCommunicationService } from '../host-communication/host-communication.service';
import { HostMessage, HostMessageType } from '../host-communication/model/HostMessage';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

/**
 * Service to provide data received from host
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _dataStatus: BehaviorSubject<DashboardDataStatus | null> = new BehaviorSubject<DashboardDataStatus | null>(null);
  public dataStatus: Observable<DashboardDataStatus | null> = this._dataStatus.asObservable();

  /**
   * An observable containing the dashboard data object filtered from the data type message
   */
  private _dashboardData: BehaviorSubject<DashboardResultGroup | null> = new BehaviorSubject<DashboardResultGroup | null>(null);
  public dashboardData: Observable<DashboardResultGroup | null> = this._dashboardData.asObservable();

  /**
   * Sex filtered from the data type message
   */
  public sex?: string;

  /**
   * Date of birth (in millies since 1970) filtered from the data type message
   */
  public dob?: number;

  constructor(private hostComms: HostCommunicationService, private translateService: TranslateService) {
    // Provide data as an observable
    hostComms.hostMessage.pipe(
      // by filtering out events that contain the actual data
      filter((message: HostMessage | null) => {
        return message && message.type == HostMessageType.DATA && message.data;
      }),
      // Map the message to the actual data
      map((message: HostMessage | null) => {
        // // Check if the required data is present on the message
        if (message?.data) {
          return this.mapData(message.data);
        }
        return null;
      })
    )
    // Send observed data to subject => https://stackoverflow.com/a/48880705
    .subscribe(this._dashboardData);

    hostComms.hostMessage.pipe(
      // by filtering out events that contain the actual data
      filter((message: HostMessage | null) => {
        return message && message.type == HostMessageType.DATA && message.data;
      }),
      map((message: HostMessage | null) => {
        return this.mapStatus(message?.data);
      })
    )
    // Send observed data to subject => https://stackoverflow.com/a/48880705
    .subscribe(this._dataStatus);
  }

  public updateData(data: any) {
    this._dashboardData.next(this.mapData(data));
    this._dataStatus.next(this.mapStatus(data));
  }

  private mapData(data: any) : DashboardResultGroup | null {
    if (data.dashboard?.result) {
      // Configure sex
      this.sex = data.dashboard.sex;
      // Configure dob
      this.dob = data.dashboard.dobMillis;

      // Configure language
      const language = environment.languages.find(language => language === data.dashboard.locale);
      if (language) {
        this.translateService.use(language);
      }
      
      return data.dashboard.result as DashboardResultGroup;
    }
    return null;
  }

  private mapStatus(data: any) : DashboardDataStatus | null {
    if (data) {
      return data.status as DashboardDataStatus;
    }
    return null;
  }
}
