import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from 'src/app/constants/Colors';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'll-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent extends BaseComponent implements OnInit {

  @Input() bgColor?: COLORS = COLORS.BLACK;
  @Input() color?: COLORS = COLORS.LL_WHITE;
  @Input() size: number = 10;
  @Input() padMultiplier: number = 0.4;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
