import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardResultGroup } from 'src/app/model/DashboardResult';

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    private _selectedGroup = new BehaviorSubject<DashboardResultGroup | null>(null);
    public selectedGroup: Observable<DashboardResultGroup | null> = this._selectedGroup.asObservable();

    public selectedGroupScrollPosition: number | null = null;

    constructor() {}

    public selectGroup(group: DashboardResultGroup): void {
        this._selectedGroup.next(group);
    }

    public resetSelectedGroup(): void {
        this._selectedGroup.next(null);
    }

    public selectGroupScrollPosition(scrollPosition: number): void {
        this.selectedGroupScrollPosition = scrollPosition;
    }

    public resetSelectedGroupScrollPosition(): void {
        this.selectedGroupScrollPosition = null;
    }
}
