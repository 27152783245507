export class HostUtil {

    /**
     * Check wether the dashboard is shown within the LL iOS application
     * @returns a boolean
     */
    static isIOS(): boolean {
        // If webkit exsists we are dealing with an iOS host
        return (<any>window).webkit?.messageHandlers?.iosEventlistener;
    }

    /**
     * Check wether the dashboard is shown within the LL Android application
     * @returns a boolean
     */
    static isAndroid(): boolean {
        // If androidEventListener exists on window we are dealing with an Android host
        return (<any>window).androidEventlistener;
    }

    /**
     * Check wether the dashboard is shown within the native leadlife app
     */
    static isNativeApp(): boolean {
        return this.isIOS() || this.isAndroid();
    }
}