import { COLORS } from "src/app/constants/Colors";

export class SliderConfig {
    name: string;
    /**
     * Value shown next to name
     */
    value: string | null | undefined;

    /**
     * Unit of value shown next to name
     */
    valueUnit: string | null | undefined;

    /**
     * Gradient score label
     */
    score: string | null | undefined;

    /**
     * Gradient score unit
     */
    scoreUnit: string | null | undefined;

    /**
     * Percentage of label position
     * Possible value range: [0.0, 1.0]
     * Recommended values range: [0.1, 0.9]
     */
    percentage: number | null | undefined;

    /**
     * Color for label above gradient
     */
    labelColor: COLORS | null | undefined;

    /**
     * Color of the slider, if null default gradient will be used
     */
    sliderColor: COLORS | null | undefined;

    /**
     * Reverse gradient: default direction left to right is red to green, when reversed it is green to red.
     */
    reverseGradient: boolean = false;

    constructor(
        name: string,
        value: string | null | undefined,
        valueUnit: string | null | undefined,
        score: string | null | undefined,
        scoreUnit: string | null | undefined,
        percentage: number | null | undefined,
        labelColor: COLORS | null | undefined,
        sliderColor: COLORS | null | undefined = null,
        reverseGradient: boolean = false,
    ) {
        this.name = name;
        this.value = value;
        this.valueUnit = valueUnit;
        this.score = score
        this.scoreUnit = scoreUnit;
        this.percentage = percentage;
        this.labelColor = labelColor;
        this.reverseGradient = reverseGradient;
        this.sliderColor = sliderColor;
    }
}
