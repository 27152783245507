import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ApexAxisChartSeries, ApexAnnotations, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexPlotOptions, ApexStates, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { COLORS } from 'src/app/constants/Colors';
import { formatNumber } from '@angular/common';
import { DashboardResultGroup, DashboardResultRiskLevel, TaggedObject } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-score-overview-graph',
  templateUrl: './score-overview-graph.component.html',
  styleUrls: []
})
export class ScoreOverviewGraphComponent extends BaseComponent implements OnInit {

  @Input('data') data!: DashboardResultGroup;

  @ViewChild("chart") chartView!: ChartComponent;

  public series!: ApexAxisChartSeries;
  public labels!: string[];
  public chart!: ApexChart;
  public xaxis!: ApexXAxis;
  public yaxis!: ApexYAxis;
  public fill!: ApexFill;
  public legend!: ApexLegend;
  public plotOptions!: ApexPlotOptions;
  public dataLabels!: ApexDataLabels;
  public markers!: ApexMarkers;
  public tooltip!: ApexTooltip;
  public states!: ApexStates;
  public annotations!: ApexAnnotations;

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    super();
  }

  ngOnInit(): void {
    let filteredData = TaggedObject.filter(
      this.data.groups,
      [
        'DASHBOARD_SUMMARY_BODY',
        'DASHBOARD_SUMMARY_MOVE',
        'DASHBOARD_SUMMARY_STRESS',
        'DASHBOARD_SUMMARY_HEALTH',
        'DASHBOARD_SUMMARY_FOOD',
        'DASHBOARD_SUMMARY_SLEEP'
      ],
      false
    ) ?? [];

    this.chart = {
      type: 'bar',
      height:  200,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      width: '100%'
    }

    this.labels = filteredData.map((grp) => grp.name);
    
    let targetData = new Array(filteredData.length).fill(0);
    this.series = filteredData.map((grp, index) => {
      let score = DashboardResultGroup.getLatestScore(grp);
      if (score) {
        let data = new Array(filteredData.length).fill(0);
        data[index] = score.score;
        targetData[index] = score.goalScore - score.score;
        return {
          name: grp.name,
          data: data,
          color: DashboardResultRiskLevel.toColor(score.riskLevel)
        }
      }
      return { name: grp.name, data: new Array(filteredData.length).fill(0), color: '#fff' };
    });
    this.series.push({
      name: 'Goal',
      data: targetData,
      color: COLORS.LL_GREY
    });

    this.xaxis = {
      labels: {
        trim: false,
        style: {
          colors: this.labels.map((label) => COLORS.LL_WHITE ),
          fontSize: '12px',
          fontFamily: 'ObjectSans'
        },
        offsetY: -3
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE
      }
    };

    this.yaxis = {
      max: 10,
      min: 0,
      tickAmount: 5,
      axisTicks: {
        show: false,
        width: 4,
        color: COLORS.LL_WHITE
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE,
      },
      labels: {
        style: {
          colors: [COLORS.LL_WHITE],
          fontSize: '10px',
          fontFamily: 'ObjectSans'
        },
        formatter: (value) => { return formatNumber(value, this.localeId, '1.1-1') }
      },
      tooltip: { enabled: false }
    };

    this.fill = {
      opacity: [...filteredData.map(() => 1), 0.4]
    };

    this.legend = {
      show: false
    };

    this.plotOptions = {
      
    };

    this.dataLabels = {
      enabled: true,
      // Creates array of numbers [0, 1, 2, 3, ...] -> the last serie is ignored since this one is not in the filterData array
      enabledOnSeries: [...Array(filteredData.length).keys()],
      formatter: (value) => {
        return Number(value) == 0 ? '' : formatNumber(Number(value), this.localeId, '1.1-1');
      }
    };

    this.annotations = {
      points: filteredData.filter((grp) => DashboardResultGroup.getLatestScore(grp)?.goalScore != null).map((grp) => {
        let goal = DashboardResultGroup.getLatestScore(grp)?.goalScore ?? 0;
        return {
          x: grp.name,
          y: goal,
          marker: {
            size: 0
          },
          label: {
            text: formatNumber(goal, this.localeId, '1.1-1'),
            borderWidth: 0,
            textAnchor: 'middle',
            offsetX: 7,
            offsetY: 23,
            style: {
              background: '#00000000',
              color: COLORS.LL_WHITE,
              fontSize: '9px',
              fontWeight: 400,
              fontFamily: 'ObjectSans',
            }
          },
          image: {
            path: '/assets/star.svg',
            width: 8,
            height: 8,
            offsetX: -9,
            offsetY: 14,
          }
        }
      })
    };

    this.markers = {
      size: 0
    };

    this.tooltip = {
      enabled: false
    }

    this.states = {
      normal: {
        filter: {
            type: 'none',
            value: 0,
        }
      },
      hover: {
          filter: {
            type: 'none',
            value: 0,
          }
      },
      active: {
          allowMultipleDataPointsSelection: false,
          filter: {
              type: 'none',
              value: 0,
          }
      },
    }
  }

}
