<div *ngIf="result">
    <div *ngIf="result.visualization == Visualization.FATPERCENTAGE">
        <ll-body-mass-graph [result]="result"></ll-body-mass-graph>
    </div>
    <div *ngIf="result.visualization == Visualization.HUMAN">HUMAN_VISUALIZATION_NOT_SUPPORTED_DYNAMICALLY</div>
    <div *ngIf="result.visualization == Visualization.SLIDER || result.visualization == Visualization.SLIDER_REVERSE">
        <ll-slider [score]="result | resultToSliderConfig" [result]="result"></ll-slider>
    </div>
    <div *ngIf="result.visualization == Visualization.TEXTUAL || result.visualization == Visualization.TEXTUAL_COLOR">
        <ll-textual
            [label]="result.name"
            [value]="(DashboardResult.getLatestValue(result) | extractResultValue) ?? '-' + ' ' + result.unit"
            [color]="result.visualization == Visualization.TEXTUAL ? COLORS.LL_WHITE : DashboardResultRiskLevel.toColor(DashboardResult.getLatestValue(this.result)?.riskLevel)"
            [result]="result"
        ></ll-textual>
    </div>
    <ll-peer-comparison *ngIf="showPeerComparison" [result]="result"></ll-peer-comparison>
</div>
