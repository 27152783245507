import { Pipe, PipeTransform } from '@angular/core';
import { COLORS } from '../constants/Colors';
import { DashboardResultRiskLevel } from '../model/DashboardResult';

/**
 * Pipe to transfrom a DashboardRiskLevel to the proper color string.
 */
@Pipe({
  name: 'riskLevelToColor'
})
export class RiskLevelToColorPipe implements PipeTransform {

  /**
   * Transfrom a DashboardRiskLevel to the proper color string.
   * @param value representing the dashboard risk level
   * @returns a color string
   */
  transform(value: DashboardResultRiskLevel): COLORS {
    return DashboardResultRiskLevel.toColor(value);
  }

}
