import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResult, DashboardResultGroup } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';

@Component({
  selector: 'll-information-page',
  templateUrl: './information-page.component.html',
  styleUrls: [
    './../layout.scss',
    './information-page.component.scss'
  ]
})
export class InformationPageComponent extends BaseComponent implements OnInit {

  public result: DashboardResult | null = null;

  public category: string = '';
  private groupTag: string = '';
  private resultTag: string = '';
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    private analytics: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.route.params.subscribe((params) => {
      this.category = params[this.APP_ROUTES['INFORMATION']['params'][0]];
      const groupTag = params[this.APP_ROUTES['INFORMATION']['params'][1]];
      const resultTag = params[this.APP_ROUTES['INFORMATION']['params'][2]];

      this.sendAnalytics('open-dashboard-information');

      this.dataService.dashboardData.pipe(
        map((group: DashboardResultGroup | null) => {
          let targetGroup = DashboardResultGroup.findNestedGroupWith(groupTag, group);
          if (targetGroup) {
            this.result = DashboardResultGroup.getResultWith(resultTag, targetGroup);
          }
        })
      ).subscribe();
    }));
  }

  sendAnalytics(key: string) {
    this.analytics.sendEvent(new Event(
      key,
      'view-results',
      'dashboard',
      new Map([
        ['group', this.groupTag ?? 'N/A'],
        ['result', this.resultTag ?? 'N/A']
      ])
    ));
  }

}
