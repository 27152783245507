import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { COLORS } from 'src/app/constants/Colors';
import { DisplayService } from 'src/app/services/display/display.service';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';

@Component({
  selector: 'll-second-display-page',
  templateUrl: './second-display-page.component.html',
  styleUrls: ['./second-display-page.component.scss']
})
export class SecondDisplayPageComponent implements OnInit {

  COLORS = COLORS;

  public sessionId: string = ''; // Used in view to generate QR: https://github.com/cordobo/angularx-qrcode

  public accessExpired: Observable<boolean> | undefined;

  constructor(private displayService: DisplayService, private router: Router, private analytics: AnalyticsService) { }

  ngOnInit(): void {
    this.displayService.initSession();
    this.displayService.sessionId.subscribe((id: string) => {
      this.sessionId = id;
    });
    this.displayService.accessGranted.subscribe((access: boolean) => {
      // If access is granted, send user to dashboard
      if (access) {
        this.router.navigate(['/']);
      }
    });
    this.accessExpired = this.displayService.accessExpired;
    this.analytics.sendEvent(new Event(
      'open-dashboard-share',
      'view-results',
      'dashboard',
      new Map()
    ));
  }

  public askPermission() {
    this.displayService.initSession();
  }
}
