<section class="global-score-section ll-card-styled fixed-height" [routerLink]="['/', APP_ROUTES['SCORE_EVOLUTION'].value]">
    <div>
        <img src="./../../../assets/leadlife.svg" alt="Leadlife logo"><br>
        <h2>{{ 'COMMON.CATEGORY.LL_SCORE' | translate }}</h2>
    </div>
    <div class="global-score" *ngIf="DashboardResultGroup.getLatestScore(data) as result">
        <p class="current-score">{{ result.score | number:'1.1-1' }}</p>
        <ll-label [bgColor]="COLORS.LL_RISK_GREEN" [size]="14">
            {{ 'PAGE.SUMMARY.HEADER.GOAL' | translate }} {{result.goalScore | number:'1.1-1' }}
        </ll-label>
    </div>
</section>