import { formatNumber } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResult } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-cortisol-graph',
  templateUrl: './cortisol-graph.component.html',
  styleUrls: ['./cortisol-graph.component.scss']
})
export class CortisolGraphComponent implements OnInit {

  @ViewChild("chart") chartView!: ChartComponent;

  @Input('results') results: DashboardResult[] | null = null;
  @Input('large') large: boolean = false;

  public series: ApexAxisChartSeries;
  public labels: string[];
  public chart: ApexChart;
  public xaxis: ApexXAxis;
  public yaxis: ApexYAxis;
  public stroke: ApexStroke;
  public fill: ApexFill;
  public markers: ApexMarkers;
  public legend: ApexLegend;
  public grid: ApexGrid;
  public tooltip: ApexTooltip;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    
    this.series = [
      {
        name: "Upper boundary",
        type: "area",
        data: [0.85, 0.29, 0.24, 0.11]
      },
      {
        name: "Lower boundary",
        type: "area",
        data: [0.47, 0.10, 0.07, 0.02]
      }
    ];

    this.labels = ["1", "2", "3", "4"]

    this.chart = {
      type: "line",
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '100%',
      height: ((<any>window).innerWidth > 700 ? 200 : 160),
    };

    this.stroke = {
      curve: "smooth",
      width: [0, 0, 1],
      colors: ['#CECECE', '#CECECE', COLORS.LL_WHITE]
    };

    this.fill = {
      type: "solid",
      opacity: [0.3, 1, 1],
      colors: ['#CECECE', '#396B6F', null]
    }

    this.markers = {
      size: 0
    };

    this.xaxis = {
      labels: {
        trim: false,
        style: {
          colors: this.labels.map((label) => COLORS.LL_WHITE ),
          fontSize: (<any>window).innerWidth > 700 ? '8px' : '6px',
          fontFamily: 'ObjectSans'
        },
        offsetY: (<any>window).innerWidth > 700 ? -3 : -6
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE
      },
    };

    this.yaxis = {
      min: 0,
      // max: 2,
      tickAmount: 5,
      axisTicks: {
        show: true,
        offsetX: 2 + 5, 
        offsetY: 1,
        width: 4,
        color: COLORS.LL_WHITE
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE,
        offsetX: -5
      },
      labels: {
        offsetX: 0 + 5,
        style: {
          colors: [COLORS.LL_WHITE],
          fontSize: (<any>window).innerWidth > 700 ? '7px' : '5px',
          fontFamily: 'ObjectSans'
        },
        formatter: (value) => { return formatNumber(value, localeId, '1.0-1') }
      },
      tooltip: { enabled: false }
    };

    this.grid = {
      yaxis: {
        lines: { show: false }
      }
    };

    this.tooltip = {
      enabled: false
    };

    this.legend = {
      show: false
    };
  }

  ngOnInit(): void {
    this.series.push({
      name: "data",
      type: "line",
      data: this.results ? this.results.map((value: DashboardResult) => DashboardResult.getLatestValue(value)?.doubleValue ?? 0) : [0,0,0,0]
    });
    if (this.large) {
      this.chart.height = this.chart.height as number * 2;
    }
  }

  shouldLock(): boolean {
    if (this.results && this.results.length == 4) {
      // Lock ff a result exists without values
      return this.results.find((result) => result.values.length == 0) != null
    }
    return true;
  }
}
