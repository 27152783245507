import { Pipe, PipeTransform } from '@angular/core';
import { COLORS } from '../constants/Colors';
import { DashboardResultRiskLevel, DashboardResultValue } from '../model/DashboardResult';

/**
 * Pipe to extract a color string from a dashboard result
 */
@Pipe({
  name: 'extractResultColor'
})
export class ExtractResultColorPipe implements PipeTransform {

  /**
   * Extract a color string from a dashboard result
   * @param value representing a dashboard result
   * @returns a color string
   */
  transform(value: DashboardResultValue | null): COLORS {
    return DashboardResultRiskLevel.toColor(value?.riskLevel);
  }

}
