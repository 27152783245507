import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardResult, DashboardResultValue } from 'src/app/model/DashboardResult';
import { ValueDebuggerService } from 'src/app/services/value-debugger/value-debugger.service';

@Component({
  selector: 'll-value-debugger',
  templateUrl: './value-debugger.component.html',
  styleUrls: ['./value-debugger.component.scss']
})
export class ValueDebuggerComponent implements OnInit {

  @Input('result') result!: DashboardResult;

  public value: DashboardResultValue | null = null;

  public debug!: Observable<boolean>;

  constructor(private valueDebugger: ValueDebuggerService) { }

  ngOnInit(): void {
    this.debug = this.valueDebugger.enabled;
    this.value = DashboardResult.getLatestValue(this.result);
  }

}
