import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResult } from 'src/app/model/DashboardResult';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'll-textual',
  templateUrl: './textual.component.html',
  styleUrls: ['./textual.component.scss']
})
export class TextualComponent implements AfterViewInit {

  DashboardResult = DashboardResult;

  @Input('label') label!: string;
  @Input('value') valueText!: string | null;
  @Input('color') valueColor!: COLORS;
  
  // For debugging purposes!
  @Input() result: DashboardResult | null | undefined = null;

  @ViewChild('value') value!: ElementRef;

  public valueDebugger: boolean = environment.valueDebugger;

  constructor(private renderer: Renderer2, private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.renderer.setStyle(
      this.value.nativeElement,
      'color',
      this.valueColor
    )
  }
}
