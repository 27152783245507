import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRouterConfig } from './AppRouterConfig';

/**
 * Router module
 */

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRouterConfig)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
