import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Service to keep track of the current value debugger settings.
 */
@Injectable({
  providedIn: 'root'
})
export class ValueDebuggerService {

  /**
   * If enabled extended inforamtion will be shown in result visualizations. By default this value should always be false.
   */
  private _enabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public enabled: Observable<boolean> = this._enabled.asObservable();

  constructor() { }

  /**
   * Toggle the enabled flag, works only if the value debugger is activated in the environment settings.
   */
  public toggleDebugValues() {
    if (environment.valueDebugger) {
      this._enabled.next(!this._enabled.value);
    }
  }
}
