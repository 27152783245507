
import { DashboardResultRiskLevel } from "src/app/model/DashboardResult";
import { PeerComparisonArray, PeerComparisonGender, PeerComparisonItem } from "src/app/model/PeerComparison";

const PEER_COMPARISON_ITEMS: PeerComparisonArray = new PeerComparisonArray(
  new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_RESTRICTIES_CAFEINE_LAAG',
      DashboardResultRiskLevel.GOOD,
      null,
      null,
      null,
      'snel',
      'vite',
      'fast',
      52,
    ),new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_RESTRICTIES_CAFEINE_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      null,
      null,
      null,
      'matig',
      'modéré',
      'intermediate',
      40,
    ),new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_RESTRICTIES_CAFEINE_HOOG',
      DashboardResultRiskLevel.BAD,
      null,
      null,
      null,
      'traag',
      'lentement',
      'slow',
      8,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_MIDDELEN_ROKEN_VERSLAVING_LAAG',
      DashboardResultRiskLevel.GOOD,
      null,
      null,
      null,
      'geen verhoogde kans',
      'peu de probabilité ',
      'little chance',
      45,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_MIDDELEN_ROKEN_VERSLAVING_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      null,
      null,
      null,
      'verhoogde kans',
      'augmentation de la probabilité',
      'increased chance',
      43,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_MIDDELEN_ROKEN_VERSLAVING_HOOG',
      DashboardResultRiskLevel.BAD,
      null,
      null,
      null,
      'hoge kans',
      'probabilité élevée',
      'high chance',
      12,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_CHRONOTYPE_OCHTEND',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'ochtend',
      'lève-tôt',
      'early bird',
      90,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_CHRONOTYPE_GEENVOORKEUR',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'geen specifieke voorkeur',
      'aucune préférence spécifique',
      'no specific preference',
      8,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_CHRONOTYPE_AVOND',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'avond',
      'oiseau de nuit',
      'night owl',
      2,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_DUUR_LANG',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'hoge nood',
      'besoin élevé',
      'high need',
      14,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_DUUR_KORT',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'weinig nood',
      'peu de besoin',
      'litlle need',
      48,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_SLAAP_DUUR_GEEN',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'geen verhoogde nood',
      'pas d’augmentation de besoin',
      'no increased need',
      38,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_STRESS_WORRIER_LAAG',
      DashboardResultRiskLevel.BAD,
      null,
      null,
      null,
      'eerder worrier',
      'plutôt worrier',
      'rather worrier',
      24,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_STRESS_WORRIER_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      null,
      null,
      null,
      'wisselend',
      'sensation inconstant',
      'varying',
      49,
    ),new PeerComparisonItem(
      'RESULT_DNA_MENTAL_STRESS_WORRIER_HOOG',
      DashboardResultRiskLevel.GOOD,
      null,
      null,
      null,
      'eerder warrior',
      'plutôt warrior',
      'rather warrior',
      27,
    ),new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_EETGEDRAG_SMAAK_BITTER_LAAG',
      DashboardResultRiskLevel.GOOD,
      null,
      null,
      null,
      'moeilijk te proeven',
      'difficile à goûter',
      'non-taster',
      30,
    ),new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_EETGEDRAG_SMAAK_BITTER_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      null,
      null,
      null,
      'neutraal',
      'neutre',
      'intermediate',
      50,
    ),new PeerComparisonItem(
      'RESULT_DNA_NUTRITION_EETGEDRAG_SMAAK_BITTER_HOOG',
      DashboardResultRiskLevel.BAD,
      null,
      null,
      null,
      'makkelijk te proeven',
      'facile à goûter',
      'super taster',
      20,
    ),new PeerComparisonItem(
      'RESULT_DNA_PERFORMANCE_SPORTPROFIEL_SPORTEN_DUURSPORT',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'duursporter',
      'athlète d’endurance',
      'endurance athlete',
      47,
    ),new PeerComparisonItem(
      'RESULT_DNA_PERFORMANCE_SPORTPROFIEL_SPORTEN_GEMENGD',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'kracht-en duursporter',
      'athlète de fortce et d’endurance',
      'strenth and endurance athlete',
      41,
    ),new PeerComparisonItem(
      'RESULT_DNA_PERFORMANCE_SPORTPROFIEL_SPORTEN_KRACHTSPORT',
      DashboardResultRiskLevel.NOT_APPLICABLE,
      null,
      null,
      null,
      'krachtsporter',
      'athlète de force ',
      'strength athlete',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'heel goed',
      'très bien',
      'very good',
      50,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'goed',
      'bien',
      'good',
      15,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      18,
      29,
      'matig',
      'neutre',
      'moderate',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'slecht',
      'mauvais',
      'bad',
      13,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'heel slecht',
      'très mauvais',
      'very bad',
      10,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'heel goed',
      'très bien',
      'very good',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'goed',
      'bien',
      'good',
      17,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      30,
      39,
      'matig',
      'neutre',
      'moderate',
      22,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'slecht',
      'mauvais',
      'bad',
      16,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'heel slecht',
      'très mauvais',
      'very bad',
      17,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'heel goed',
      'très bien',
      'very good',
      25,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'goed',
      'bien',
      'good',
      10,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      40,
      49,
      'matig',
      'neutre',
      'moderate',
      27,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'slecht',
      'mauvais',
      'bad',
      18,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'heel slecht',
      'très mauvais',
      'very bad',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'heel goed',
      'très bien',
      'very good',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'goed',
      'bien',
      'good',
      16,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      50,
      59,
      'matig',
      'neutre',
      'moderate',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'slecht',
      'mauvais',
      'bad',
      16,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'heel slecht',
      'très mauvais',
      'very bad',
      26,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'heel goed',
      'très bien',
      'very good',
      5,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good',
      17,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      60,
      999,
      'matig',
      'neutre',
      'moderate',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'slecht',
      'mauvais',
      'bad',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'heel slecht',
      'très mauvais',
      'very bad',
      22,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'heel goed',
      'très bien',
      'very good',
      55,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'goed',
      'bien',
      'good',
      13,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      18,
      29,
      'matig',
      'neutre',
      'moderate',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'slecht',
      'mauvais',
      'bad',
      8,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'heel slecht',
      'très mauvais',
      'very bad',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'heel goed',
      'très bien',
      'very good',
      40,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'goed',
      'bien',
      'good',
      15,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      30,
      39,
      'matig',
      'neutre',
      'moderate',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'slecht',
      'mauvais',
      'bad',
      11,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'heel slecht',
      'très mauvais',
      'very bad',
      14,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'heel goed',
      'très bien',
      'very good',
      29,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'goed',
      'bien',
      'good',
      13,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      40,
      49,
      'matig',
      'neutre',
      'moderate',
      26,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'slecht',
      'mauvais',
      'bad',
      12,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'heel slecht',
      'très mauvais',
      'very bad',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'heel goed',
      'très bien',
      'very good',
      22,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'goed',
      'bien',
      'good',
      17,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      50,
      59,
      'matig',
      'neutre',
      'moderate',
      23,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'slecht',
      'mauvais',
      'bad',
      16,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'heel slecht',
      'très mauvais',
      'very bad',
      22,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_GOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'heel goed',
      'très bien',
      'very good',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good',
      14,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      60,
      999,
      'matig',
      'neutre',
      'moderate',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'slecht',
      'mauvais',
      'bad',
      25,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_INSPANNING_VO2MAX_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'heel slecht',
      'très mauvais',
      'very bad',
      13,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'goed',
      'bien',
      'good',
      38,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      18,
      29,
      'verlaagd',
      'réduit',
      'too low',
      7,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      25,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'goed',
      'bien',
      'good',
      40,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      30,
      39,
      'verlaagd',
      'réduit',
      'too low',
      10,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      22,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'goed',
      'bien',
      'good',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      40,
      49,
      'verlaagd',
      'réduit',
      'too low',
      11,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      31,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      24,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'goed',
      'bien',
      'good',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      50,
      59,
      'verlaagd',
      'réduit',
      'too low',
      7,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      35,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      60,
      999,
      'verlaagd',
      'réduit',
      'too low',
      7,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      35,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      28,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'goed',
      'bien',
      'good',
      29,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      18,
      29,
      'verlaagd',
      'réduit',
      'too low',
      1,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      36,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'goed',
      'bien',
      'good',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      30,
      39,
      'verlaagd',
      'réduit',
      'too low',
      1,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      35,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'goed',
      'bien',
      'good',
      36,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      40,
      49,
      'verlaagd',
      'réduit',
      'too low',
      1,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      31,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      32,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'goed',
      'bien',
      'good',
      42,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      50,
      59,
      'verlaagd',
      'réduit',
      'too low',
      4,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good',
      42,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_NEUTRAL',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      60,
      999,
      'verlaagd',
      'réduit',
      'too low',
      4,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_VETPERCENTAGE_VERY_BAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'heel goed',
      'trés bien',
      'very good',
      48,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'goed',
      'bien',
      'good',
      38,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      18,
      29,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      13,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      1,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'heel goed',
      'trés bien',
      'very good',
      48,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'goed',
      'bien',
      'good',
      35,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      30,
      39,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      16,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      1,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'heel goed',
      'trés bien',
      'very good',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'goed',
      'bien',
      'good',
      44,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      40,
      49,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      2,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'heel goed',
      'trés bien',
      'very good',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'goed',
      'bien',
      'good',
      40,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      50,
      59,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      25,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      5,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'heel goed',
      'trés bien',
      'very good',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good',
      40,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.F,
      60,
      999,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      25,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      5,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'heel goed',
      'trés bien',
      'very good',
      10,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'goed',
      'bien',
      'good',
      50,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      18,
      29,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      30,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      10,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'heel goed',
      'trés bien',
      'very good',
      8,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'goed',
      'bien',
      'good',
      36,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      30,
      39,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      36,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'heel goed',
      'trés bien',
      'very good',
      5,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'goed',
      'bien',
      'good',
      24,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      40,
      49,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      37,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      34,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'heel goed',
      'trés bien',
      'very good',
      4,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'goed',
      'bien',
      'good',
      19,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      50,
      59,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      44,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      33,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYGOOD',
      DashboardResultRiskLevel.VERY_GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'heel goed',
      'trés bien',
      'very good',
      4,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_GOOD',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good',
      19,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_MATIG',
      DashboardResultRiskLevel.NEUTRAL,
      PeerComparisonGender.M,
      60,
      999,
      'niet optimaal',
      'pas optimale',
      'not ideal',
      0,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_BAD',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'licht verhoogd',
      'un peu trop élevé',
      'slightly elevated',
      44,
    ),new PeerComparisonItem(
      'RESULT_LEADLIFE_MEASUREMENT_DXA_AGRATIO_VERYBAD',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      33,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'goed',
      'bien',
      'good ',
      70,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      10,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'goed',
      'bien',
      'good ',
      82,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      10,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      8,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'goed',
      'bien',
      'good ',
      71,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      11,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      18,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'goed',
      'bien',
      'good ',
      79,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      14,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      7,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good ',
      71,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      29,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'goed',
      'bien',
      'good ',
      60,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      10,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      30,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'goed',
      'bien',
      'good ',
      55,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      20,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      25,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'goed',
      'bien',
      'good ',
      62,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      14,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      24,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'goed',
      'bien',
      'good ',
      85,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      5,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      10,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good ',
      84,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      16,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_SPORT_OVERTRAINING_CK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      6,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      39,
      'goed',
      'bien',
      'good ',
      94,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      17,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      59,
      'goed',
      'bien',
      'good ',
      81,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      2,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good ',
      100,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      11,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      39,
      'goed',
      'bien',
      'good ',
      82,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      7,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      4,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      59,
      'goed',
      'bien',
      'good ',
      90,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      6,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      33,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good ',
      50,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      17,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_VOEDING_VITAMINES_ZINK_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'verlaagd',
      'réduit',
      'slightly reduced',
      18,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      18,
      29,
      'goed',
      'bien',
      'good ',
      64,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      18,
      29,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      18,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      11,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      30,
      39,
      'goed',
      'bien',
      'good ',
      83,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      30,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      6,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'verlaagd',
      'réduit',
      'slightly reduced',
      6,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      40,
      49,
      'goed',
      'bien',
      'good ',
      88,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      40,
      49,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      3,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      3,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      3,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      14,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      50,
      59,
      'goed',
      'bien',
      'good ',
      83,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      50,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      14,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.F,
      60,
      999,
      'goed',
      'bien',
      'good ',
      86,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.F,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.F,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'verlaagd',
      'réduit',
      'slightly reduced',
      10,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      18,
      29,
      'goed',
      'bien',
      'good ',
      70,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      18,
      29,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      18,
      29,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      22,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'verlaagd',
      'réduit',
      'slightly reduced',
      9,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      30,
      39,
      'goed',
      'bien',
      'good ',
      91,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      30,
      39,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      30,
      39,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'verlaagd',
      'réduit',
      'slightly reduced',
      7,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      40,
      49,
      'goed',
      'bien',
      'good ',
      89,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      40,
      49,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      40,
      49,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      4,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'verlaagd',
      'réduit',
      'slightly reduced',
      15,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      50,
      59,
      'goed',
      'bien',
      'good ',
      80,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      50,
      59,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      50,
      59,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      5,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAGLAAG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verlaagd',
      'trop réduit',
      'significantly reduced',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_LAAG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verlaagd',
      'réduit',
      'slightly reduced',
      17,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_NORMAAL',
      DashboardResultRiskLevel.GOOD,
      PeerComparisonGender.M,
      60,
      999,
      'goed',
      'bien',
      'good ',
      83,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOG',
      DashboardResultRiskLevel.BAD,
      PeerComparisonGender.M,
      60,
      999,
      'verhoogd ',
      'un peu trop élevé',
      'slightly elevated',
      0,
    ),new PeerComparisonItem(
      'RESULT_BLOOD_MEDICAL_BLOOD_IJZER_HOOGHOOG',
      DashboardResultRiskLevel.VERY_BAD,
      PeerComparisonGender.M,
      60,
      999,
      'sterk verhoogd',
      'trop élevé',
      'significantly elevated',
      0,
    )
);

export default PEER_COMPARISON_ITEMS;
    