import { Component, Input } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/modules/routing/AppRoutes';
import { HostUtil } from 'src/app/utility/host';
import { HostCommunicationService } from 'src/app/services/host-communication/host-communication.service';
import { ClientMessage, ClientMessageType } from 'src/app/services/host-communication/model/ClientMessage';

@Component({
  selector: 'll-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {

  @Input() category: string | null = null;
  @Input() page: string | null = null;
  @Input() navigateToSummary: boolean = false;

  constructor(
    private analytics: AnalyticsService,
    private location: Location,
    private translateService: TranslateService,
    private router: Router,
    private host: HostCommunicationService
  ) {
    super();
  }

  public back(): void {
    this.analytics.sendEvent(new Event(
      `return-from-dashboard-${this.page}`,
      'view-results',
      'dashboard',
      new Map([
        ['category', this.category ?? 'N/A']
      ])
    ));

    if (HostUtil.isNativeApp()) {
      this.host.sendEvent(new ClientMessage(ClientMessageType.CLOSE))
    } else if (this.navigateToSummary) {
      this.router.navigate(['/', APP_ROUTES['SUMMARY'].value]);
    } else {
      this.location.back();
    }
  }

  public getTitle() {
    const cleanKey = this.category?.toUpperCase();
    return this.translateService.get(`COMMON.CATEGORY.${cleanKey}`)
  }
  
  public getIcon(): string {
    const cleanKey = this.category?.toLowerCase();
    return `../../../../assets/icons/${cleanKey}.svg`
  }
}
