import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, mergeMap, Subscription } from 'rxjs';
import { DataService } from '../data/data.service';
import { NetworkService } from '../network/network.service';
import { SessionStorageKeys } from '../session-storage/session-storage-keys';
import { SessionStorageService } from '../session-storage/session-storage.service';
import {v4 as uuidv4} from 'uuid';
import { SecondDeviceDataLink } from 'src/app/model/SecondDeviceDataLink';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/modules/routing/AppRoutes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  private interval = interval(environment.displayPollingInterval);
  private intervalSubscription: Subscription | null = null;
  private deviceId: string = '';

  private _sessionId = new BehaviorSubject<string>('');
  public sessionId = this._sessionId.asObservable();

  private _accessGranted = new BehaviorSubject<boolean>(false);
  public accessGranted = this._accessGranted.asObservable();

  private _accessExpired = new BehaviorSubject<boolean>(false);
  public accessExpired = this._accessExpired.asObservable();

  constructor(
    private network: NetworkService,
    private session: SessionStorageService,
    private dataService: DataService,
    private router: Router
  ) {}

  public initSession() {
    // If no session id available -> create new session
    if (!this.session.getItem(SessionStorageKeys.SESSION_ID) && !this.session.getItem(SessionStorageKeys.DEVICE_ID)) {
      // Cleanup session storage to be sure
      this.session.clearStorage();
      // Create a device id to initiate a second display session
      this.deviceId = uuidv4();
      // Store device id to use it later on
      this.session.saveItem(SessionStorageKeys.DEVICE_ID, this.deviceId);
      // Start a new second display session
      this.network.startSecondDisplaySession(this.deviceId).subscribe((sessionId: string) => {
        // Save the session id for use later on (when screen refreshes we don't want a new session to be created)
        this.session.saveItem(SessionStorageKeys.SESSION_ID, sessionId);
        this._sessionId.next(sessionId);
        this.startPolling();
      });
    }
  }

  public reinitSession() {
    if (this.session.getItem(SessionStorageKeys.SESSION_ID) && this.session.getItem(SessionStorageKeys.DEVICE_ID)) {
      // retrieve previous ids
      this._sessionId.next(this.session.getItem(SessionStorageKeys.SESSION_ID)!);
      this.deviceId = this.session.getItem(SessionStorageKeys.DEVICE_ID)!;
      this.startPolling();
    }
  }

  /**
   * Poll session status
   */
  private startPolling() {
    // Start polling for session status
    this.intervalSubscription = this.interval.pipe(
      mergeMap(() => {
        return this.network.getSecondDisplaySessionStatus(this._sessionId.value, this.deviceId);
      })
    ).subscribe((result: SecondDeviceDataLink) => {
      if (result.otp) {
        this._accessGranted.next(true);
        this.intervalSubscription?.unsubscribe();
        this.handleOTP(result.otp);
      } else {
        // a linking session is in progress but access is not granted -> redirect to show QR until access is granted
        this.router.navigate([APP_ROUTES['SECOND_DISPLAY'].value]);
      }
    });
  }

  /**
   * Handle otp
   * @param otp 
   */
  private handleOTP(otp: string) {
    this.network.getDashboardDataForSecondDisplay(otp).subscribe((data: any) => {
      this.dataService.updateData(data);
    }, (err) => {
      console.error('ACCESS EXPIRED');
      this.session.clearStorage();
      this._accessExpired.next(true);
      console.error('NAVIGATE SECOND DISPLAY SCREEN');
      this.router.navigate([APP_ROUTES['SECOND_DISPLAY'].value]);
    });
  }
}
