<div class="logo">
    <img src="assets/logo_leadlife.svg" alt="leadlife logo">
</div>
<div class="ll-col-ctr">
    <div *ngIf="!(accessExpired | async) else expired">
        <h1>Dashboard projecteren</h1>
        <p>Scan onderstaande QR code in de leadlife app om je dashboard hier te projecteren.</p>
        <qrcode *ngIf="sessionId"
            [qrdata]="sessionId"
            [width]="256"
            [errorCorrectionLevel]="'M'"
            [alt]="'QR code'"
            [colorDark]="COLORS.LL_AQUA_DEEP"
            [colorLight]="COLORS.LL_WHITE"
            [elementType]="'img'"
            class="qr"
        ></qrcode>
        <p>Of geef onderstaande code manueel in:</p>
        <p>{{ sessionId }}</p>
    </div>
    <ng-template #expired>
        <p>De toegang is verlopen, <a (click)="askPermission()"><u>vraag toegang opnieuw aan</u></a></p>
    </ng-template>
</div>