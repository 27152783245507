import { Component, Input, OnInit } from '@angular/core';
import { DashboardResult, DashboardResultGroup, DashboardResultRiskLevel, DashboardResultVisualization } from 'src/app/model/DashboardResult';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'll-result',
  templateUrl: './result.component.html',
  styleUrls: []
})
export class ResultComponent extends BaseComponent implements OnInit {

  DashboardResultRiskLevel = DashboardResultRiskLevel;

  Visualization = DashboardResultVisualization;

  @Input() group: DashboardResultGroup | undefined;
  @Input() result: DashboardResult | undefined;
  @Input() showPeerComparison: boolean = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // Add not available value when no values are available -> in the past, before evolution, the backend did this but it's better to handle this here since evolution is added
    if (this.result?.values.length == 0) {
      if (!this.result.unit) {
        this.result.unit = ''; // make empty to avoid 'undefined' renders
      }
      this.result.values.push({
        riskLevel:          DashboardResultRiskLevel.NOT_AVAILABLE,
        doubleValue:        0,
        intValue:           0,
        stringValue:        '',
        tag:                '',
        resultText:         '',
        keyWords:           '',
        advice:             '',
        score:              0,
        maxScore:           0,
        goalScore:          0,
        time:               0
      });
    }
  }

}
