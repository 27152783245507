<div #chartcontainer>
    <apx-chart
    *ngIf="legend && series"
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [stroke]="stroke"
    [markers]="markers"
    [legend]="legend"
    [grid]="grid"
    [tooltip]="tooltip"
    [fill]="fill"
    >
    </apx-chart>
</div>