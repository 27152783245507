<main>
    <ll-header [category]="category" [page]="'category'" [navigateToSummary]="true"></ll-header>
    <section class="content">
        <ll-category-nav-bar [selectedCategory]="category"></ll-category-nav-bar>
        <div class="categories">
            <div class="overflow">
                <div class="ll-card" *ngFor="let group of categoryGroup?.groups" (click)="selectGroup(group)" [class.selected]="selectedGroup?.tag == group.tag">
                    <p>{{ group.name }}</p>
                </div>
            </div>
        </div>
        <div class="col ll-column-ctr">
            <div class="overflow-mask values ll-dark-shading" *ngIf="selectedGroup">
                <div class="ll-overflow-y" #scrollableElement>
                    <div *ngIf="selectedGroup.scoreResult && selectedGroup.scoreResult.length > 0" class="ll-card-styled">
                        <div class="score-container">
                            <h2>{{ 'COMMON.SCORE' | translate }}</h2>
                            <ll-score [resultGroup]="selectedGroup" [enableEvolution]="true"></ll-score>
                        </div>
                        <ll-leadlife-score-graph [resultGroup]="selectedGroup"></ll-leadlife-score-graph>
                    </div>
                    <div *ngFor="let subgroup of selectedGroup.groups" class="ll-card-styled">
                        <h2 *ngIf="subgroup.results && subgroup.results.length > 0">
                            {{ subgroup.name }}
                        </h2>
                        <!-- exclude tags starting with RESULT_LEADLIFE_CORTISOL_MEASUREMENT, they have a specific visualization -->
                        <ll-cortisol-graph
                            *ngIf="
                                subgroup.tag == 'DASHBOARD_STRESS_NIVEAU_GRAPH' &&
                                subgroup.results &&
                                (subgroup.results | filterTags:['RESULT_LEADLIFE_CORTISOL_MEASUREMENT']:false:true) as data
                            "
                            [results]="data"
                            [large]="true"
                        ></ll-cortisol-graph>
                        <div *ngFor="let result of subgroup.results | filterTags:['RESULT_LEADLIFE_CORTISOL_MEASUREMENT']:true:true; let i = index;" class="result">
                            <div>
                                <ll-result
                                    [result]="result" 
                                    [group]="subgroup" 
                                    llResultEvolution 
                                    [llResultEvolutionType]="category" 
                                    [llResultEvolutionGroup]="subgroup"
                                    [llResultEvolutionResult]="result"
                                ></ll-result>
                            </div>
                            <hr *ngIf="i != subgroup.results.length - 1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>