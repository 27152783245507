<main>
    <section>
        <div class="menu">
            <ll-summary-header [data]="data | async"></ll-summary-header>
            <ll-circle-navigation [data]="data | async"  [selectedCategory]="selectedCategory" (selectCategoryEvent)="selectCategory($event)"></ll-circle-navigation>    
        </div>
        <div class="col ll-column-ctr">
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_BODY'" class="ll-card-styled overflow-mask ll-light-shading" id="body" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'body']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_BODY') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.BODY' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div class="body-scan">
                        <img src="./../../../assets/body_scan.svg" class="body-scan" alt="body icon">
                        <span *ngIf="((group).results | findTag:'RESULT_LEADLIFE_MEASUREMENT_DXA_BUIKVET') as result">
                            {{ DashboardResult.getLatestValue(result) | extractResultValue }} {{ result.unit }}
                        </span>
                        <span *ngIf="((group).results | findTag:'RESULT_LEADLIFE_MEASUREMENT_DXA_HEUPVET') as result">
                            {{ DashboardResult.getLatestValue(result) | extractResultValue }} {{ result.unit }}
                        </span>
                    </div>
                    <div class="ll-row-ctr">
                        <div>
                            <div *ngFor="let result of (group.results | filterTags:['RESULT_LEADLIFE_MEASUREMENT_DXA_BUIKVET', 'RESULT_LEADLIFE_MEASUREMENT_DXA_HEUPVET']:true | limitItems:5); let i = index;">
                                <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                                <hr *ngIf="i != (group.results | filterTags:['RESULT_LEADLIFE_MEASUREMENT_DXA_BUIKVET', 'RESULT_LEADLIFE_MEASUREMENT_DXA_HEUPVET']:true)!.length - 1">
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_MOVE'" class="ll-card-styled overflow-mask ll-light-shading" id="move" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'move']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_MOVE') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.MOVE' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <div *ngFor="let result of (group.results | limitItems:4); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_STRESS'" class="ll-card-styled overflow-mask ll-light-shading" id="stress" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'stress']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_STRESS') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.STRESS' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <ll-cortisol-graph [results]="group.results | filterTags:['RESULT_LEADLIFE_CORTISOL_MEASUREMENT']:false:true"></ll-cortisol-graph>
                        <div *ngFor="let result of (group.results | limitItems:3); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_HEALTH'" class="ll-card-styled overflow-mask ll-light-shading" id="health" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'health']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_HEALTH') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.HEALTH' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <div *ngFor="let result of (group.results | limitItems:4); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_FOOD'" class="ll-card-styled overflow-mask ll-light-shading" id="sleep" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'food']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_FOOD') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.FOOD' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <div *ngFor="let result of (group.results | limitItems:5); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_SLEEP'" class="ll-card-styled overflow-mask ll-light-shading" id="sleep" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'sleep']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_SLEEP') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.SLEEP' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <div *ngFor="let result of (group.results | limitItems:3); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
            <article *ngIf="selectedCategory === 'DASHBOARD_SUMMARY_MOTIVATION'" class="ll-card-styled overflow-mask ll-light-shading" id="motivation" [routerLink]="['/', APP_ROUTES['CATEGORY'].value, 'motivation']">
                <div class="ll-overflow-y" *ngIf="( (data | async)?.groups | findTag:'DASHBOARD_SUMMARY_MOTIVATION') as group">
                    <div class="title">
                        <h2>{{ 'COMMON.CATEGORY.MOTIVATION' | translate }}</h2>
                        <ll-score [resultGroup]="group"></ll-score>
                    </div>
                    <div>
                        <div *ngFor="let result of (group.results | limitItems:3); let i = index;">
                            <ll-result [result]="result" [showPeerComparison]="false"></ll-result>
                            <hr *ngIf="i != group.results.length - 1">
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </section>
</main>
