<div>
    <h3>{{title}}</h3>
    <div class="stacked-bar">
        <div class="stacked-bar-items">
            <div *ngFor="let item of items" class="stacked-bar-item">
                <div
                    class="stacked-bar-item-highlight"
                    [style.width.%]="(item.value / itemsValueTotal) * 100"
                    [style.background-color]="item.color"
                >
                    <p>
                        {{((item.value / itemsValueTotal) * 100).toFixed(0)}}%
                    </p>
                </div>
                <div
                    class="stacked-bar-item-background"
                ></div>
            </div>
        </div>
        <div class="stacked-bar-pointer-items">
            <div *ngFor="let item of items" class="stacked-bar-pointer-item">
                <p *ngIf="item.label === activeItem?.label">◀</p>
                <p *ngIf="item.label === activeItem?.label">{{'COMPONENT.PEER_COMPARISON.ACTIVE_TIP' | translate}}</p>
            </div>
        </div>
    </div>
    <div class="legend">
        <div
            *ngFor="let item of items"
            class="legend-item"
        >
            <div class="legend-color" [style.background-color]="item.color"></div>
            <p>{{item.label}}</p>
        </div>
    </div>
</div>