/**
 * Keys available to store in session storage
 */
export enum SessionStorageKeys {

    /**
     * Device id used to initiate second dispaly session
     */
    DEVICE_ID = 'device-id',

    /**
     * Session id (used for second dispaly sessions) received from backend
     */
    SESSION_ID = 'session-id'
}
