import { Message } from "./Message";

/**
 * Messages send from client to host
 */
export class ClientMessage extends Message<ClientMessageType> {
  type: ClientMessageType;

  constructor(type: ClientMessageType, data: any = null) {
    super(data);
    this.type = type;
  }
}

export enum ClientMessageType {

  /**
   * This means the client is ready to receive messages from the host
   */
  READY = 'READY',

  /**
   * This means the client sends an analytics message
   */
  ANALYTICS = 'ANALYTICS',

  /**
   * This means the client wants to terminate
   */
  CLOSE = 'CLOSE'
}