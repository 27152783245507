<apx-chart
    *ngIf="annotations"
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [fill]="fill"
    [labels]="labels"
    [markers]="markers"
    [legend]="legend"
    [grid]="grid"
    [tooltip]="tooltip"
    [plotOptions]="plotOptions"
    [annotations]="annotations"
    [dataLabels]="dataLabels"
>
</apx-chart>