import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { COLORS } from "../constants/Colors";
import { DashboardResult, DashboardResultGroup, DashboardResultValue } from "../model/DashboardResult";
import { APP_ROUTES } from "../modules/routing/AppRoutes";
import { LocalizedRoutes } from "../modules/routing/model/LocalizedRoutes";

@Component({
    template: ''
}) 
export abstract class BaseComponent implements OnDestroy {

    // Expose classes to views
    DashboardResultGroup = DashboardResultGroup;
    DashboardResult = DashboardResult;
    DashboardResultValue = DashboardResultValue;
    COLORS = COLORS;

    // Routes object to use in component controller and view. With this object the route tags can be fetched
    public APP_ROUTES: LocalizedRoutes = APP_ROUTES;

    // Object to keep subscriptions of child class
    private subscriptions: Subscription[] = [];

    /**
     * Unsubscribe all subscriptions in the child class on destroy to avoid memory leaks
     * Based on unsubscribe functionality at https://malcoded.com/posts/angular-async-pipe
     */
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach((s: Subscription, index: number) => {
                s.unsubscribe();
            });
        }
    }

    /**
     * With this method a child class can register it's subscriptions in the BaseComponent parent class
     * @param subscripiton to register
     */
    protected addSubscription(subscripiton: Subscription): void {
        if (!this.subscriptions) {
            this.subscriptions = [];
        }
        this.subscriptions.push(subscripiton);
    }
}