<main *ngIf="result && DashboardResult.getLatestValue(result) as value">
    <ll-header [category]="category" [page]="'information'"></ll-header>
    <section class="content">
        <section class="ll-card-styled overflow-mask ll-light-shading">
            <div class="ll-overflow-y">
                <div class="information-header">
                    <h2>{{result.name}}</h2>
                    <ll-label *ngIf="value.keyWords" [bgColor]="value.riskLevel | riskLevelToColor" [size]="14" [padMultiplier]="0.3">
                        {{value.keyWords}}
                    </ll-label>
                </div>
                <div *ngIf="value.resultText">
                    <p>{{value.resultText}}</p>
                </div>
                <div *ngIf="value.advice">
                    <h3>{{ 'PAGE.INFORMATION.ADVICE_TITLE' | translate }}</h3>
                    <markdown class="test" [data]="value.advice"></markdown>
                </div>
                <div *ngIf="result.values.length > 1">
                    <h3>{{ 'PAGE.INFORMATION.EVOLUTION_TITLE' | translate }}</h3>
                    <div *ngIf="DashboardResultValue.isNumeric(value)" class="value-evolution">
                        <ll-value-evolution [result]="result"></ll-value-evolution>
                    </div>
                    <div *ngIf="!DashboardResultValue.isNumeric(value)" class="label-evolution">
                        <ll-label-evolution [result]="result"></ll-label-evolution>
                    </div>
                </div>
            </div>
        </section>
    </section>
</main>