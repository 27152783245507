import { Message } from "./Message";

/**
 * Messages send from host to client
 */
export class HostMessage extends Message<HostMessageType> {
  type: HostMessageType;

  /**
   * A host should notify the web client about it's OS 
   */
  host: HostType;

  constructor(messageType: HostMessageType, host: HostType, data: any = null) {
    super(data);
    this.type = messageType;
    this.host = host;
  }
}

export enum HostType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB'
}

export enum HostMessageType {
  /**
   * This type means the event contains data
   */
  DATA = 'DATA'
}
