<div id="event-debugger" *ngIf="showLastEvent">{{ message | async | json }}</div>
<div id="value-debugger" *ngIf="valueDebug" (tap)="toggleValueDebugger($event)"></div>
<div class="router-container">
    <div *ngIf="((dataAvailable | async) != null || secondDisplay) else spinner">
        <router-outlet *ngIf="(dataAvailable | async) || secondDisplay else nodashboard"></router-outlet>
        <div *ngIf="(dataStatus | async) == DashboardDataStatus.EXPIRED && (dataAvailable | async)" class="update">{{ 'STATUS_ERRORS.NOT_UP_TO_DATE' | translate }}</div>
    </div>
</div>
<ng-template #spinner>
    <div id="spinner">
        <ll-spinner></ll-spinner>
    </div>
</ng-template>
<ng-template #nodashboard>
    <div class="ll-container ll-column-ctr nodashboard">
        <p *ngIf="(dataStatus | async) == DashboardDataStatus.NOT_AVAILABLE">{{ 'STATUS_ERRORS.NOT_AVAILABLE' | translate }}</p>
        <p *ngIf="(dataStatus | async) == DashboardDataStatus.EXPIRED">{{ 'STATUS_ERRORS.EXPIRED' | translate }}</p>
    </div>
</ng-template>