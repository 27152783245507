import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResultGroup, DashboardResultRiskLevel, DashboardScoreResult } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-circle-navigation',
  templateUrl: './circle-navigation.component.html',
  styleUrls: ['./circle-navigation.component.scss'],
})
export class CircleNavigationComponent extends BaseComponent implements OnInit {
  RiskLevel = DashboardResultRiskLevel

  @Input('data') data: DashboardResultGroup | null = null;
  @Input() selectedCategory: string | null = null;

  @Output() selectCategoryEvent = new EventEmitter<string>();

  public healthResult: DashboardScoreResult | null = null;
  public bodyResult: DashboardScoreResult | null = null;
  public stressResult: DashboardScoreResult | null = null;
  public moveResult: DashboardScoreResult | null = null;
  public sleepResult: DashboardScoreResult | null = null;
  public foodResult: DashboardScoreResult | null = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.healthResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_HEALTH') ?? null)
    this.bodyResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_BODY') ?? null)
    this.stressResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_STRESS') ?? null)
    this.moveResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_MOVE') ?? null)
    this.sleepResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_SLEEP') ?? null)
    this.foodResult = DashboardResultGroup.getLatestScore(this.data?.groups.find(g => g.tag === 'DASHBOARD_SUMMARY_FOOD') ?? null)
  }

  onSelectCategory(category: string) {
    this.selectCategoryEvent.emit(category);
  }

  getFill(selected: boolean, riskLevel: DashboardResultRiskLevel | undefined): string {
    if (selected) {
      switch(riskLevel) {
        case DashboardResultRiskLevel.VERY_BAD:
            return 'url(#very-bad-gradient)';
        case DashboardResultRiskLevel.BAD:
          return 'url(#bad-gradient)';
        case DashboardResultRiskLevel.NEUTRAL:
          return 'url(#neutral-gradient)';
        case DashboardResultRiskLevel.GOOD:
          return 'url(#good-gradient)';
        case DashboardResultRiskLevel.VERY_GOOD:
          return 'url(#very-good-gradient)';
        case DashboardResultRiskLevel.NOT_APPLICABLE:
          return 'url(#not-applicable-gradient)';
        case DashboardResultRiskLevel.NOT_AVAILABLE:
        default:
          return 'url(#not-available-gradient)';
      }
    } else {
      return COLORS.LL_AQUA_LIGHT;
    }
  }
}
