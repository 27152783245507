<div *ngIf="DashboardResultGroup.getLatestScore(group) as result">
    <div *ngIf="summary else template" class="summary-style">
        <ll-label [bgColor]="result.riskLevel | riskLevelToColor" [size]="14" [padMultiplier]="0.3">
            {{ result.score | number:'1.1-1' }}
        </ll-label>
        <div class="ll-triangle-right"></div>
        <ll-label [bgColor]="COLORS.LL_RISK_GREEN" [size]="14" [padMultiplier]="0.3">
            {{ result.goalScore | number:'1.1-1' }}
        </ll-label>
    </div>
    <ng-template #template>
        <div class="default-style">
            <div>
                <img src="./../../../assets/star.svg" alt="star">
                <span>{{ result.goalScore | number:'1.1-1' }}</span>
            </div>
            <ll-label [bgColor]="result.riskLevel | riskLevelToColor" [size]="14">
                {{ result.score | number:'1.1-1' }}
            </ll-label>
        </div>
    </ng-template>
</div>