import { EnvironmentType } from "./EnvironmentType";

export const environment: EnvironmentType = {
  production:                 true,
  defaultLanguage:            'nl',
  languages:                  ['nl', 'fr', 'en'],
  showMessageDebugger:        false,
  valueDebugger:              true,
  populateDataAtInit:         false,
  peerComparisonEnabled:      true,
  informationPageEnabled:     true,
  expertAppOrigin:            'https://backoffice.development.emma.health',
  apiBase:                    'https://api2.development.emma.health/rest/v1',
  displayPollingInterval:     3000,
};
