import { Injectable } from '@angular/core';
import PEER_COMPARISON_ITEMS from './peer-comparison-data';
import { DataService } from '../data/data.service';
import { PeerComparisonGender } from 'src/app/model/PeerComparison';
import { calculateAge } from 'src/app/utility/helpers';

@Injectable({
  providedIn: 'root'
})
export class PeerComparisonDataService {
  public gender: PeerComparisonGender;
  public age: number;

  constructor(private dataService: DataService) {
    this.gender = PeerComparisonGender[this.dataService.sex as keyof typeof PeerComparisonGender]
    
    const dob = this.dataService.dob;
    this.age = dob ? calculateAge(dob) : 0;
  }

  getItems(key: string) {
    return PEER_COMPARISON_ITEMS.getItems(key, this.gender, this.age);
  }
}


