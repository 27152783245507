import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResult } from 'src/app/model/DashboardResult';
import { SliderConfig } from './SliderConfig';

@Component({
  selector: 'll-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SummaryScoreComponent implements AfterViewInit {

  COLORS = COLORS;

  triangleCorrection: number = 0;

  // It could look silly the result is passed and the config which is made on that same result
  // The reason the result is also added was because of the value debugger that had to be available
  // on this visualization too.
  @Input() score: SliderConfig | undefined;
  @Input() result!: DashboardResult;

  @ViewChild('gradient', { static: false }) gradient!: ElementRef;
  @ViewChild('label', { static: false }) label!: ElementRef;
  @ViewChild('triangle', { static: false }) triangle!: ElementRef;

  constructor(private renderer: Renderer2, private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.calculateOffset();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: Event) {
    this.calculateOffset();
  }

  private calculateOffset() {
    if (this.score?.percentage) {
      // Calculate position from left of gradient for center of label
      let positionLeft = this.gradient.nativeElement.offsetWidth * this.score.percentage - this.label.nativeElement.offsetWidth / 2;
      
      // Calculate available space on right of label
      let availableRight = this.gradient.nativeElement.offsetWidth - (positionLeft + this.label.nativeElement.offsetWidth);

      // For calculation of left availablility we can use the positionLeft variable

      // If no space remains we should adjust the positionLeft variable
      if (positionLeft > 0 && availableRight < 0) {
        // Change triangle position so it is still positioned correctly on the slider
        this.renderer.setStyle(this.triangle.nativeElement, 'margin-right', `${availableRight - 33}px`);
        // Change label position so it does not go out of boundary
        positionLeft = positionLeft + availableRight - 10;
      } else if (positionLeft < 0) {
        // Change triangle position so it is still positioned correctly on the slider
        this.renderer.setStyle(this.triangle.nativeElement, 'margin-left', `${positionLeft - 33}px`);
        // Change label position so it does not go out of boundary
        positionLeft = 0;
      }

      this.renderer.setStyle(this.label.nativeElement, 'left', `${positionLeft}px`);
      if (this.score.sliderColor) {
        this.renderer.setStyle(this.gradient.nativeElement, 'background-color', this.score.sliderColor);
        this.renderer.setStyle(this.gradient.nativeElement, 'background-image', 'none');
      }
    }
  }
}
