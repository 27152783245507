import { Pipe, PipeTransform } from '@angular/core';
import { TaggedObject } from '../model/DashboardResult';

/**
 * Pipe to find a taggable object (read: object with tag property) in a list of taggable objects.
 * At time of writing DashboardResult & DashboardResultGroup are the used taggable objects.
 */
@Pipe({
  name: 'findTag'
})
export class FindTagPipe implements PipeTransform {

  /**
   * Transform an input list of taggable objects to the required object.
   * @param values the list of taggable objects.
   * @param tag the tag of the object to retrieve.
   * @returns an optional taggable object.
   */
  transform<T extends TaggedObject>(values: T[] | null | undefined, tag: string): T | null {
    if (values) {
      return values.find((value: T) => value && value.tag && value.tag == tag) ?? null;
    }
    return null;
  }

}
