import { Component, Input } from '@angular/core';
import { DashboardResultGroup } from 'src/app/model/DashboardResult';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'll-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent extends BaseComponent {

  @Input('resultGroup') group!: DashboardResultGroup;
  @Input('summaryView') summary: boolean = true;
  @Input('enableEvolution') evolutionEnabled = false;

  constructor() {
    super();
  }
}
