import { LocalizedRoutes } from "./model/LocalizedRoutes";

/**
 * App routes for the main app module
 * Date: 05/03/2020
 * Time: 14:30
 *
 * @author: thijsvm
 */
export const APP_ROUTES: LocalizedRoutes = {
    SUMMARY:                { value: 'summary' },
    RAW_DATA:               { value: 'raw-data', params: ['category'] },
    CATEGORY:               { value: 'category', params: ['category'] },
    SCORE_EVOLUTION:        { value: 'score-evolution' },
    AGE_EVOLUTION:          { value: 'age-evolution' },
    SECOND_DISPLAY:         { value: 'display' },
    INFORMATION:            { value: 'information', params: ['category', 'groupTag', 'resultTag'] },
};
