import { formatNumber } from "@angular/common";
import { COLORS } from "../constants/Colors";

/**
 * Interface to define a taggable object
 */
export interface TaggedObject {
    tag:                string;
}

/**
 * Namespace to extend the TaggedObject interface with static functions
 */
export namespace TaggedObject {

    export function filter<T extends TaggedObject>(
        values: T[] | null | undefined,
        tags: string[],
        exclude: boolean,
        startsWidth: boolean = false
    ): T[] | null {
        if (values && tags) {
            return values.filter((value: T) => {
                if (value && value.tag) {
                    let tag = tags.find((tag: string) => startsWidth ? value.tag.startsWith(tag) : value.tag == tag)
                    return exclude ? tag == null : tag != null
                }
                return false
            }) ?? null;
        }
        return null;
    }
}

export enum DashboardDataStatus {
    UP_TO_DATE          = "UPTODATE",
    NOT_AVAILABLE       = "NOT_AVAILABLE",
    EXPIRED             = "EXPIRED"
}

/**
 * Object that serves as a building block for the dashboard
 */
export interface DashboardResultGroup extends TaggedObject {
    tag:                string;
    name:               string;
    content:            string; // Extra info (not in use yet)
    groups:             DashboardResultGroup[]; // Ordered by date ascending on backend
    results:            DashboardResult[];
    scoreResult:        DashboardScoreResult[]; // Ordered by date ascending on backend
}

/**
 * Namespace to extend the DashboardResultGroup interface with static functions
 */
export namespace DashboardResultGroup {
    
    export function getLatestScore(group: DashboardResultGroup | null): DashboardScoreResult | null {
        if (group?.scoreResult && group.scoreResult.length > 0) {
            return group.scoreResult[group.scoreResult.length - 1];
        }
        return null;
    }

    export function findNestedGroupWith(tag: string, group: DashboardResultGroup | null): DashboardResultGroup | null {
        if (group?.tag == tag) return group;
        for (const grp of group?.groups ?? []) {
            const grpHit = findNestedGroupWith(tag ,grp);
            if (grpHit) return grpHit;
        }
        return null;
    }

    export function getResultWith(tag: string, group: DashboardResultGroup): DashboardResult | null {
        return group.results.find(result => result.tag == tag) ?? null;
    }
}

/**
 * Object representing a dashboard result
 */
export interface DashboardResult extends TaggedObject {
    tag:                string;
    name:               string;
    unit:               string;
    visualization:      DashboardResultVisualization;
    values:             DashboardResultValue[];
    source:             string;
}

/**
 * Namespace to extend the DashboardResult interface with static functions
 */
 export namespace DashboardResult {

    export function getLatestValue(result: DashboardResult): DashboardResultValue | null{
        if (result != null && result.values.length > 0) {
            const [highestValueObject] = [...result.values].sort((a, b) => b.time - a.time);
            return highestValueObject;
        }
        return null;
    }

    export function getMaxValue(result: DashboardResult): DashboardResultValue {
        return result.values.reduce(function(prev, current) {
            return (prev.doubleValue ?? prev.intValue > current.doubleValue ?? current.intValue) ? prev : current
        })
    }

    export function getMinValue(result: DashboardResult): DashboardResultValue {
        return result.values.reduce(function(prev, current) {
            return (prev.doubleValue ?? prev.intValue < current.doubleValue ?? current.intValue) ? prev : current
        })
    }
}

export interface DashboardResultValue {
    riskLevel:          DashboardResultRiskLevel;
    doubleValue:        number;
    intValue:           number;
    stringValue:        string;
    tag:                string;
    resultText:         string;
    keyWords:           string;
    advice:             string;
    score:              number;
    maxScore:           number;
    goalScore:          number;
    /**
     * millies since 1970
     */
     time:               number;
}

/**
 * Namespace to extend the DashboardResultValue interface with static functions
 */
 export namespace DashboardResultValue {

    /**
     * Extract the value from a result object various properties.
     * This function looks for a value in the following order.
     * 1. doubleValue
     * 2. intValue
     * 3. stringValue
     * @param result to extract a value from
     * @param localeId to localize the number values
     * @returns 
     */
    export function extractValue(value: DashboardResultValue | null, localeId: string): string | null {
        if (value) {
            if (value.doubleValue || value.intValue) {
                let numValue = value.doubleValue ?? value.intValue
                return String(formatNumber(numValue, localeId, '1.0-2'));
            }
            return value.stringValue && value.stringValue.trim() != '' ? value.stringValue : null;
        }
        return null;
    }

    export function isNumeric(result: DashboardResultValue): boolean {
        return result.doubleValue != undefined || result.intValue != undefined
    }
}

/**
 * Risk levels a result can have
 */
export enum DashboardResultRiskLevel {
    VERY_BAD            = "VERY_BAD",
    BAD                 = "BAD",
    NEUTRAL             = "NEUTRAL",
    GOOD                = "GOOD",
    VERY_GOOD           = "VERY_GOOD",
    NOT_APPLICABLE      = "NOT_APPLICABLE",
    NOT_AVAILABLE       = "NOT_AVAILABLE"
}

/**
 * Namespace to extend the DashboardResultRiskLevel with static functions
 */
export namespace DashboardResultRiskLevel {

    /**
     * Get color associated to a risk level
     * @param level to get a color from
     * @returns a color enum (string)
     */
    export function toColor(level: DashboardResultRiskLevel | undefined | null): COLORS {
        switch(level) {
            case DashboardResultRiskLevel.VERY_BAD:
                return COLORS.LL_RISK_RED;
            case DashboardResultRiskLevel.BAD:
                return COLORS.LL_RISK_ORANGE;
            case DashboardResultRiskLevel.NEUTRAL:
                return COLORS.LL_RISK_YELLOW;
            case DashboardResultRiskLevel.GOOD:
                return COLORS.LL_RISK_GREEN_LIGHT;
            case DashboardResultRiskLevel.VERY_GOOD:
                return COLORS.LL_RISK_GREEN;
            case DashboardResultRiskLevel.NOT_APPLICABLE:
                return COLORS.LL_DODGER;
            case DashboardResultRiskLevel.NOT_AVAILABLE:
            default:
                return COLORS.LL_GREY;
        }
    }

    /**
     * Check if a risk is scaled, aka not not_applicable or not_available
     * @param level to check
     * @returns if a given risk is a scaled risk
     */
    export function isScaled(level: DashboardResultRiskLevel): boolean {
        return level != DashboardResultRiskLevel.NOT_APPLICABLE && level != DashboardResultRiskLevel.NOT_AVAILABLE;
    }
}

/**
 * Visualizations a result can have
 */
export enum DashboardResultVisualization {
    SLIDER              = "SLIDER",             // visulaization: SliderComponent
    SLIDER_REVERSE      = "SLIDER_REVERSE",     // visulaization: SliderComponent
    HUMAN               = "HUMAN",              // visulaization: Hardcoded in summary itself
    TEXTUAL             = "TEXTUAL",            // visulaization: TextualComponent
    TEXTUAL_COLOR       = "TEXTUAL_COLOR",      // visulaization: TextualComponent
    FATPERCENTAGE       = "FATPERCENTAGE",      // visulaization: BodyMassGraphComponent
    CORTISOL            = "CORTISOL"            // visulaization: CortisolGraphComponent
}

export interface DashboardScoreResult {
    riskLevel:      DashboardResultRiskLevel;
    stringValue:    string;
    score:          number;
    maxScore:       number;
    goalScore:      number;
    realAge:        number;
    leadlifeAge:    number;
    goalAge:        number;
    time:           number;
}


/**
 * Namespace to extend the DashboardScoreResult with static functions
 */
export namespace DashboardScoreResult {

}