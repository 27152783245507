import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DashboardDataStatus, DashboardResultGroup } from './model/DashboardResult';
import { APP_ROUTES } from './modules/routing/AppRoutes';
import { DataService } from './services/data/data.service';
import { DisplayService } from './services/display/display.service';
import { HostCommunicationService } from './services/host-communication/host-communication.service';
import { HostMessage } from './services/host-communication/model/HostMessage';
import { ValueDebuggerService } from './services/value-debugger/value-debugger.service';

/**
 * Root component for this application. It contains
 * - The container for our router
 * - A spinner that should appear when no data is available:
 *      For testing purposes outside a mobile in app webview you could run following command in your browsers console:
 *      `window.postMessage("{\"type\":\"DATA\",\"host\":\"IOS\",\"data\":\"base64 encoded data\"}");`
 * - A debug window that appears in development mode to show the last incoming message
 * - A debug activation button for extra value information
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  DashboardDataStatus = DashboardDataStatus;

  /**
   * Show last event in template when message debugger is activated in environment config
   */
  showLastEvent: boolean = environment.showMessageDebugger;

  /**
   * Activate hidden button at bottom left to allow users activate the debug value display
   */
  valueDebug: boolean = environment.valueDebugger;

  /**
   * Messages from host, inly used for messafe debugger
   */
  message: Observable<HostMessage | null>;

  /**
   * The dashboard status received from host (iOS/Android), used to show spinner (when waiting for the data from host), text (when no
   * dashboard dat is available from host) or the dashboard
   */
  dataStatus: Observable<DashboardDataStatus | null>;

  /**
   * Flag to indicate of data available in dashboard
   */
  dataAvailable: Observable<boolean | null>;

  /**
   * 
   */
  secondDisplay: boolean = false;

  constructor(
    private hostComm: HostCommunicationService,
    private dataService: DataService,
    private valueDebugger: ValueDebuggerService,
    private router: Router,
    // Import service over here so the service constructor is called independent of what page is rendered
    private display: DisplayService
  ) {
    this.message = hostComm.hostMessage;
    this.dataStatus = dataService.dataStatus;
    this.dataAvailable = dataService.dashboardData.pipe(
      map((data: DashboardResultGroup | null) => {
        if (data) {
          return data.groups.length > 0;
        }
        return null;
      })
    );
  }

  ngOnInit(): void {
    // this.secondDisplay = this.route.snapshot.url == APP_ROUTES['SECOND_DISPLAY'].value
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe(event => {
      let e = event as NavigationStart;
      this.secondDisplay = e.url == `/${APP_ROUTES['SECOND_DISPLAY'].value}`
    });
    this.display.reinitSession();
  }

  /**
   * Method used by the value debugger button in template. When the user taps the button 5 times it will toggle the value debugger display.
   * Taps are registered by HammerJS.
   * @param event passed from tap event in template
   */
  public toggleValueDebugger(event: any) {
    if (event.tapCount == 5) {
      this.valueDebugger.toggleDebugValues();
    }
  }
}
