import { Component, OnInit } from '@angular/core';

/**
 * A component to show a spinner.
 * provided by https://loading.io/css/ under the CC0 license
 */
@Component({
  selector: 'll-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
