import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { SliderConfig } from '../components/result/visualization/slider/SliderConfig';
import { COLORS } from '../constants/Colors';
import { DashboardResult, DashboardResultRiskLevel, DashboardResultValue, DashboardResultVisualization } from '../model/DashboardResult';

/**
 * Pipe to transfrom a dashboard result to a slider config object.
 */
@Pipe({
  name: 'resultToSliderConfig'
})
export class ResultToSliderConfigPipe implements PipeTransform {

  /**
   * String representing the current configured locale.
   */
  private localeId: string

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.localeId = localeId;
  }

  /**
   * Transform a dashboard result to a slider config.
   * @param result to transfrom in a slider config
   * @returns a proper formed slider config object.
   */
  transform(result: DashboardResult): SliderConfig {
    // Determine if slider should be reversed: default is Red to Green, inversed is Green to Red.
    let reverse = result.visualization == DashboardResultVisualization.SLIDER_REVERSE;
    let latestValue = DashboardResult.getLatestValue(result);
    return new SliderConfig(
      result.name,
      null,
      null,
      // Configure the label value
      DashboardResultValue.extractValue(latestValue, this.localeId),
      // Configure the label unit
      result.unit,
      // Calculate horizontal percentage position
      this.getPercentage(latestValue?.riskLevel, reverse),
      // Determine the label color
      DashboardResultRiskLevel.toColor(latestValue?.riskLevel),
      // Determine the slider color
      this.getSliderColor(latestValue?.riskLevel),
      // Set reverse flag
      reverse
    );
  }

  /**
   * Calculate the horizontal position for the label on the slider (in percentage). When reversed the positions are inverted (Notice we do not invert the color of the labels).
   * @param riskLevel of the result
   * @param reverse slider
   * @returns an optional percentage (if null no label will be shown)
   */
  private getPercentage(riskLevel: DashboardResultRiskLevel | undefined | null, reverse: boolean): number | null | undefined {
    switch (riskLevel) {
      case DashboardResultRiskLevel.VERY_BAD:
        return reverse ? 0.9 : 0.1;
      case DashboardResultRiskLevel.BAD:
        return reverse ? 0.7 : 0.3;
      case DashboardResultRiskLevel.NOT_APPLICABLE: // no value is associated so position in center
      case DashboardResultRiskLevel.NOT_AVAILABLE: // no value is associated so position in center
      case DashboardResultRiskLevel.NEUTRAL:
        return 0.5;
      case DashboardResultRiskLevel.GOOD:
        return reverse ? 0.3 : 0.7;
      case DashboardResultRiskLevel.VERY_GOOD:
        return reverse ? 0.1 : 0.9;
      default:
        return null;
    }
  }

  /**
   * Deterimine slider color for the slider.
   * @param riskLevel to calculate a color for.
   * @returns an optional color string. If null we do not override the slider gradient
   */
  private getSliderColor(riskLevel: DashboardResultRiskLevel | undefined | null): COLORS | null {
    // Only when the value is null, NOT_APPLICABLE or NOT_AVAILABLE we have to set the slider color!
    if (!riskLevel) {
      return null;
    } else if ([DashboardResultRiskLevel.NOT_APPLICABLE, DashboardResultRiskLevel.NOT_AVAILABLE].includes(riskLevel)) {
      return DashboardResultRiskLevel.toColor(riskLevel)
    }
    return null;
  }
}
