import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResultGroup } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'll-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent extends BaseComponent {

  data: Observable<DashboardResultGroup | null>

  selectedCategory: string | null = 'DASHBOARD_SUMMARY_BODY';

  constructor(private dataService: DataService) {
    super();
    this.data = this.dataService.dashboardData.pipe(
      map((d: DashboardResultGroup | null) => {
        if (d?.groups) {
          const dashboardSummary: DashboardResultGroup | undefined = d.groups.find((grp: DashboardResultGroup) => {
            return grp.tag == 'DASHBOARD_SUMMARY';
          });
          if (dashboardSummary) {
            return dashboardSummary;
          }
        }
        return null;
      })
    );
  }

  selectCategory(selectedCategory: string) {
    this.selectedCategory = selectedCategory;
  }

  canShowBodyFigure(group: DashboardResultGroup): boolean {
    const [waistFat] = group.results.filter(result => result.tag === 'RESULT_LEADLIFE_MEASUREMENT_DXA_BUIKVET');
    const [hipFat] = group.results.filter(result => result.tag === 'RESULT_LEADLIFE_MEASUREMENT_DXA_HEUPVET');

    return waistFat?.unit.length > 0 && hipFat?.unit.length > 0;
  }
}
