import { Injectable } from '@angular/core';
import { SessionStorageKeys } from './session-storage-keys';

/**
 * Service that acts as interface to use the session storage
 * @author thijsvm
 */
@Injectable({providedIn: 'root'})
export class SessionStorageService {

    private storage: Storage = sessionStorage;

    constructor() {

    }

    public saveItem(key: SessionStorageKeys, value: string): void {
        this.storage.setItem(key, value);
    }

    public saveObject<T>(key: SessionStorageKeys, value: T): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    public getItem(key: SessionStorageKeys): string | null {
        return this.storage.getItem(key);
    }

    public getObject<T>(key: SessionStorageKeys): T | null {
        let item = this.storage.getItem(key)
        if (item) {
            return JSON.parse(item) as T;
        }
        return null;
    }

    public remove(key: SessionStorageKeys): void {
        this.storage.removeItem(key);
    }

    public clearStorage(): void {
        this.storage.clear();
    }
}
