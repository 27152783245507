import { Injectable } from '@angular/core';
import { HostCommunicationService } from './host-communication.service';
import { ClientMessage, ClientMessageType } from './model/ClientMessage';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(private hostCommsService: HostCommunicationService) {

    }

    public sendEvent(event: Event) {
        this.hostCommsService.sendEvent(new ClientMessage(
            ClientMessageType.ANALYTICS,
            event
        ));
    }
}

export class Event {
    public name: string
    public category: string
    public subcategory: string
    public params: any

    constructor(
        name: string,
        category: string,
        subcategory: string,
        params: Map<string, string>
    ) {
        this.name = name;
        this.category = category;
        this.subcategory = subcategory;
        this.params = Object.fromEntries(params);
    }
}