import { formatDate, formatNumber } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResultGroup, DashboardResultRiskLevel } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-leadlife-score-graph',
  templateUrl: './leadlife-score-graph.component.html',
  styleUrls: []
})
export class LeadlifeScoreGraphComponent implements OnChanges {

  @Input('resultGroup') group!: DashboardResultGroup;

  @ViewChild("chart") chartView!: ChartComponent;

  public series: ApexAxisChartSeries;
  public labels: string[];
  public chart: ApexChart;
  public xaxis: ApexXAxis;
  public yaxis: ApexYAxis;
  public fill: ApexFill;
  public markers: ApexMarkers;
  public legend: ApexLegend;
  public grid: ApexGrid;
  public tooltip: ApexTooltip;
  public plotOptions: ApexPlotOptions;
  public annotations: ApexAnnotations | null = null;
  public dataLabels: ApexDataLabels;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private translateService: TranslateService
  ) {
    this.series = [
      {
        name: "",
        data: []
      }
    ];

    this.labels = []

    this.chart = {
      type: "bar",
      height:  200,
      zoom: {
        enabled: false
      },
      toolbar: { show: false },
      width: '100%'
    };

    this.fill = {};

    this.markers = {
      size: 0
    };

    this.xaxis = {
      labels: {
        trim: false,
        style: {
          colors:  COLORS.LL_WHITE ,
          fontSize: '8px',
          fontFamily: 'ObjectSans'
        },
        offsetY: -3
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE
      }
    };

    this.yaxis = {
      min: 0,
      max: 10,
      tickAmount: 5,
      axisTicks: {
        show: false,
        width: 4,
        color: COLORS.LL_WHITE
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE,
      },
      labels: {
        style: {
          colors: [COLORS.LL_WHITE],
          fontSize: '10px',
          fontFamily: 'ObjectSans'
        },
        formatter: (value) => { return formatNumber(value, localeId, '1.1-1') }
      },
      tooltip: { enabled: false }
    };

    this.translateService.get('COMPONENT.LEADLIFE_SCORE_GRAPH.GOAL').subscribe((translation) => {
      this.annotations = {
        yaxis: [{
          y: 5,
          strokeDashArray: 6,
          borderColor: COLORS.LL_WHITE,
          label: {
            borderWidth: 0,
            text: translation,
            textAnchor: 'end',
            position: 'right',
            style: {
                background: 'none',
                color: COLORS.LL_WHITE,
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: 'ObjectSans'
            },
        },
        }]
      };
    });

    this.dataLabels = {
      formatter: (value) => { return Number(value) == 0 ? '' : formatNumber(Number(value), localeId, '1.1-1') }
    }

    this.plotOptions = {
      bar: {
        distributed: true,
        columnWidth: '40%'
      }
    };

    this.grid = {
      borderColor: '#558083',
      yaxis: {
        lines: { show: true }
      }
    };

    this.legend = {
      show: false
    };

    this.tooltip = {
      enabled: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only update the graph when another tag is present -> avoid loops
    if (changes['group']?.currentValue?.tag != changes['group']?.previousValue?.tag) {
      this.updateGraph();
    }
  }

  private updateGraph() {
    type nullableScore = { riskLevel: DashboardResultRiskLevel; stringValue: string | null ; score: number | null; maxScore: number | null; goalScore: number | null; realAge: number | null; leadlifeAge: number | null; goalAge: number | null; time: number | null;  };
    // Copy score array by value
    let scores = [...this.group.scoreResult] as [nullableScore];
    if (scores.length < 5) {
      // Add empty values to avoid single large bar in chart
      scores = Object.assign([], new Array(5).fill({
        riskLevel:      DashboardResultRiskLevel.NOT_AVAILABLE,
        stringValue:    null,
        score:          null,
        maxScore:       null,
        goalScore:      null,
        realAge:        null,
        leadlifeAge:    null,
        goalAge:        null,
        time:           null  
      }), scores);
    } else if (scores.length > 7) {
      // Show last 7 items (when more items are shown the graph is not readable)
      scores = scores.slice(-7) as [nullableScore];
    }
    this.series[0].data = scores.map((score) => score.score);
    this.labels = scores.map((score) => score.time ? formatDate(new Date(score.time), 'dd.MM.yy', this.localeId) : "");
    if (this.annotations) {
      this.annotations.yaxis![0].y = scores[0].goalScore;
    }
    this.fill = {
      type: "solid",
      colors: scores.map((score) => DashboardResultRiskLevel.toColor(score.riskLevel))
    };
  }
}
