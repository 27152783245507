import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeNlBe from '@angular/common/locales/nl-BE'

import { AppRoutingModule } from './modules/routing/app-routing.module';

import { AppComponent } from './app.component';

import { SummaryPageComponent } from './pages/summary-page/summary-page.component';
import { RawDataPageComponent } from './pages/raw-data-page/raw-data-page.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { environment } from 'src/environments/environment';

import { LabelComponent } from './components/label/label.component';
import { SummaryScoreComponent } from './components/result/visualization/slider/slider.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FindTagPipe } from './pipes/find-tag.pipe';
import { ExtractResultValuePipe } from './pipes/extract-result-value.pipe';
import { ExtractResultColorPipe } from './pipes/extract-result-color.pipe';
import { ResultToSliderConfigPipe } from './pipes/result-to-slider-config.pipe';
import { FilterTagsPipe } from './pipes/filter-tags.pipe';
import { RiskLevelToColorPipe } from './pipes/risk-level-to-color.pipe';
import { ResultComponent } from './components/result/result.component';
import { TextualComponent } from './components/result/visualization/textual/textual.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CortisolGraphComponent } from './components/result/visualization/graphs/cortisol-graph/cortisol-graph.component';
import { LeadlifeScoreGraphComponent } from './components/result/visualization/graphs/leadlife-score-graph/leadlife-score-graph.component';
import { BodyMassGraphComponent } from './components/result/visualization/graphs/body-mass-graph/body-mass-graph.component';
import { ValueDebuggerComponent } from './components/value-debugger/value-debugger.component';
import { MilliesToDatePipe } from './pipes/millies-to-date.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';
import { RiskLevelCircleComponent } from './components/risk-level-circle/risk-level-circle.component';
import { SecondDisplayPageComponent } from './pages/second-display-page/second-display-page.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { ValueEvolutionComponent } from './directives/result-evolution/value-evolution/value-evolution.component';
import { LabelEvolutionComponent } from './directives/result-evolution/label-evolution/label-evolution.component';
import { ScoreComponent } from './components/score/score.component';
import { LLAgeEvolutionComponent } from './components/llage-evolution/llage-evolution.component';
import { ScoreOverviewGraphComponent } from './components/score-overview-graph/score-overview-graph.component';
import { AgeAndScoreEvolutionPageComponent } from './pages/age-and-score-evolution-page/age-and-score-evolution-page.component';
import { LimitItemsPipe } from './pipes/limit-items.pipe';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { PeerComparisonComponent } from './components/peer-comparison/peer-comparison.component';
import { InformationPageComponent } from './pages/information-page/information-page.component';
import { ResultEvolutionDialogComponent } from './directives/result-evolution/result-evolution-dialog/evolution-dialog.component';
import { ResultEvolutionDirective } from './directives/result-evolution/result-evolution.directive';
import { TranslateModule as NgxTranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { httpTranslationLoaderFactory } from './modules/translation/translation.module';
import { HeaderComponent } from './components/header/header.component';
import { MarkdownModule } from 'ngx-markdown';
import { CategoryNavBarComponent } from './components/category-nav-bar/category-nav-bar.component';
import { CircleNavigationComponent } from './pages/summary-page/circle-navigation/circle-navigation.component';
import { SummaryHeaderComponent } from './pages/summary-page/summary-header/summary-header.component';

/**
 * Register nl-BE local to allow correct date, number currency, ... formatting.
 */
registerLocaleData(localeNlBe, 'nl-BE');

/**
 * HammerJS config: used for touch events
 */
 @Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SummaryPageComponent,
    RawDataPageComponent,
    CategoryPageComponent,
    LabelComponent,
    SummaryScoreComponent,
    SpinnerComponent,
    FindTagPipe,
    ExtractResultColorPipe,
    ExtractResultValuePipe,
    ResultToSliderConfigPipe,
    FilterTagsPipe,
    RiskLevelToColorPipe,
    ResultComponent,
    TextualComponent,
    CortisolGraphComponent,
    LeadlifeScoreGraphComponent,
    BodyMassGraphComponent,
    ValueDebuggerComponent,
    MilliesToDatePipe,
    RiskLevelCircleComponent,
    SecondDisplayPageComponent,
    ValueEvolutionComponent,
    LabelEvolutionComponent,
    ScoreComponent,
    LLAgeEvolutionComponent,
    ScoreOverviewGraphComponent,
    AgeAndScoreEvolutionPageComponent,
    LimitItemsPipe,
    StackedBarChartComponent,
    PeerComparisonComponent,
    InformationPageComponent,
    ResultEvolutionDialogComponent,
    ResultEvolutionDirective,
    HeaderComponent,
    CategoryNavBarComponent,
    CircleNavigationComponent,
    SummaryHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgApexchartsModule,
    HttpClientModule,
    // https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0
    // https://angular.io/api/platform-browser/HammerGestureConfig
    HammerModule,
    BrowserAnimationsModule,
    MaterialModule,
    QRCodeModule,
    NgxTranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: httpTranslationLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot(),
  ],
  providers: [
    // Provide the HammerJS config from above
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    // Provide the registered local from above
    { provide: LOCALE_ID, useValue: 'nl-BE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(translate: TranslateService) {
    // Configure language
    translate.setDefaultLang(environment.defaultLanguage);
  }
}