import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardResult } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-evolution-dialog',
  templateUrl: './evolution-dialog.component.html',
  styleUrls: ['./evolution-dialog.component.scss']
})
export class ResultEvolutionDialogComponent implements OnInit {

  public showLabelEvolution!: boolean;

  constructor(
    public dialogRef: MatDialogRef<ResultEvolutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardResult
  ) { }

  ngOnInit(): void {
    let latestValue = DashboardResult.getLatestValue(this.data);
    this.showLabelEvolution = latestValue?.doubleValue == undefined && latestValue?.intValue == undefined;
  }

}
