import { translate } from "../utility/helpers";
import { DashboardResultRiskLevel } from "./DashboardResult";
import { LanguageTranslations } from "./Translate";

export class PeerComparisonArray extends Array<PeerComparisonItem> {
    getItems(key: string, gender: PeerComparisonGender, age: number) {
        const items = this.filter(item => key && item.key.startsWith(key));
        const filteredItems = items.filter(
            item =>  
                (item.gender === null || item.gender === gender) &&  
                (item.minAge === null || age >= item.minAge) &&  
                (item.maxAge === null || age <= item.maxAge) &&
                item.percentage !== 0
        );
        return filteredItems
    }
}

export class PeerComparisonItem {
    key: string;
    gender: PeerComparisonGender | null;
    minAge: number | null;
    maxAge: number | null;
    option: DashboardResultRiskLevel | null;
    labelNl: string;
    labelFr: string;
    labelEn: string;
    percentage: number;

    constructor(
        key: string,
        option: DashboardResultRiskLevel | null,
        gender: PeerComparisonGender | null,
        minAge: number | null,
        maxAge: number | null,
        labelNl: string,
        labelFr: string,
        labelEn: string,
        percentage: number,
    ) {
        this.key = key,
        this.gender = gender,
        this.minAge = minAge,
        this.maxAge = maxAge,
        this.option = option,
        this.labelNl = labelNl,
        this.labelFr = labelFr,
        this.labelEn = labelEn,
        this.percentage = percentage
    }

    getLabel(language: string) {
        const langTranslations = new LanguageTranslations(
            this.labelNl,
            this.labelFr,
            this.labelEn
        )
    
        return translate(language, langTranslations);
      }
}

export enum PeerComparisonGender {
    F,
    M,
}
