export class LanguageTranslations {
    public nl: string = '';
    public fr: string = '';
    public en: string = '';

    constructor(
        nl: string,
        fr: string,
        en: string,
    ) {
        this.nl = nl;
        this.fr = fr;
        this.en = en;
    }
}