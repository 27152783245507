import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResult, DashboardResultRiskLevel } from 'src/app/model/DashboardResult';
import { PeerComparisonDataService } from 'src/app/services/peer-comparison-data/peer-comparison-data.service';
import { ChartItemType } from 'src/app/model/Chart';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

const NOT_APPLICABLE_COLORS = ['#487d99', '#62b6cb', '#8db59e']

@Component({
  selector: 'll-peer-comparison',
  templateUrl: './peer-comparison.component.html',
  styleUrls: ['./peer-comparison.component.scss']
})
export class PeerComparisonComponent extends BaseComponent implements OnInit {
  public showPeerComparison = environment.peerComparisonEnabled;

  @Input() result: DashboardResult | null | undefined = null;

  public items: ChartItemType[] = [];
  public activeItem: ChartItemType | null = null;
  public title!: Observable<string>

  constructor(
    private peerComparisonDataService: PeerComparisonDataService,
    private translateService: TranslateService,
  ) {
    super();

    this.peerComparisonDataService = peerComparisonDataService;
  }

  ngOnInit(): void {
    const items = this.peerComparisonDataService.getItems(this.result?.tag ?? '');

    const mostRecentValue = this.result?.values[this.result?.values.length -  1];
    const preferredActiveItem = items?.find(item => 
      item.key === mostRecentValue?.tag
    );
    const alternativeActiveItem = items?.find(item => 
      item.option === mostRecentValue?.riskLevel
    );
    const activeItem = preferredActiveItem ?? alternativeActiveItem;

    const showActiveOption = mostRecentValue?.riskLevel !== DashboardResultRiskLevel.NOT_AVAILABLE;
    this.activeItem = showActiveOption && activeItem ? {
      label: activeItem.getLabel(this.translateService.currentLang),
      value: activeItem.percentage,
      color: DashboardResultRiskLevel.toColor(activeItem.option as DashboardResultRiskLevel),
    } : null

    this.items = items?.map((item, index) => {
      const riskColor = DashboardResultRiskLevel.toColor(item.option as DashboardResultRiskLevel);
      const color = 
        item.option === DashboardResultRiskLevel.NOT_APPLICABLE ? 
        NOT_APPLICABLE_COLORS[index] :
        riskColor

      return {
      label: item.getLabel(this.translateService.currentLang),
      value: item.percentage,
      color,
    }});

    this.title = this.translateService.get('COMPONENT.PEER_COMPARISON.TITLE',  { name: this.result?.name.toLowerCase() })
  }
}
