import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform date in millies (number) to a date.
 */
@Pipe({
  name: 'milliesToDate'
})
export class MilliesToDatePipe implements PipeTransform {

  transform(value: number): Date | null {
    if (value) {
      return new Date(value);
    }
    return null;
  }

}
