import { Pipe, PipeTransform } from '@angular/core';
import { TaggedObject } from '../model/DashboardResult';

/**
 * Pipe to filter a list of taggable objects (read: objects with tag property)
 */
@Pipe({
  name: 'filterTags'
})
export class FilterTagsPipe implements PipeTransform {

  /**
   * Filter a list of taggable objects based on some fitlers
   * @param values the input list of taggable objects
   * @param tags a list of tags to filter on
   * @param exclude flag to indicate if the tags should be included (false) ot excluded (true)
   * @param startsWidth flag to check if one of the values starts width one of the specified tags, if not they must be the same
   * @returns 
   */
  transform<T extends TaggedObject>(values: T[] | null | undefined, tags: string[], exclude: boolean, startsWidth: boolean = false): T[] | null {
    return TaggedObject.filter(values, tags, exclude, startsWidth);
  }

}
