import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResultGroup } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-summary-header',
  templateUrl: './summary-header.component.html',
  styleUrls: ['./summary-header.component.scss'],
})
export class SummaryHeaderComponent extends BaseComponent {

  @Input('data') data: DashboardResultGroup | null = null;

  constructor() {
    super();
  }
}
