import { formatNumber } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResult, DashboardResultValue } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'll-body-mass-graph',
  templateUrl: './body-mass-graph.component.html',
  styleUrls: ['./body-mass-graph.component.scss']
})
export class BodyMassGraphComponent implements OnInit {

  @Input("result") result!: DashboardResult;

  public latestValue: DashboardResultValue | null = null;

  @ViewChild("chart") chartView!: ChartComponent;

  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public xaxis: ApexXAxis | null = null;
  public yaxis: ApexYAxis | null = null;
  public stroke: ApexStroke;
  public fill: ApexFill;
  public markers: ApexMarkers;
  public legend: ApexLegend;
  public grid: ApexGrid;
  public tooltip: ApexTooltip;
  public annotations: ApexAnnotations;

  private seriesMale = [
    {
      name: "90-boundary",
      type: "area",
      data: [{x: 20, y: 28}, {x: 30, y: 30}, {x: 40, y: 31}, {x: 50, y: 34}, {x: 60, y: 38}, {x: 100, y: 38}]
    },
    {
      name: "75-boundary",
      type: "area",
      data: [{x: 20, y: 24}, {x: 30, y: 26}, {x: 40, y: 27}, {x: 50, y: 30}, {x: 60, y: 34}, {x: 100, y: 34}]
    },
    {
      name: "50-boundary",
      type: "line",
      data: [{x: 20, y: 18}, {x: 30, y: 20}, {x: 40, y: 22}, {x: 50, y: 24}, {x: 60, y: 26}, {x: 100, y: 26}]
    },
    {
      name: "25-boundary",
      type: "area",
      data: [{x: 20, y: 8}, {x: 30, y: 11}, {x: 40, y: 13}, {x: 50, y: 15}, {x: 60, y: 17}, {x: 100, y: 17}]
    },
    {
      name: "10-boundary",
      type: "area",
      data: [{x: 20, y: 4}, {x: 30, y: 7}, {x: 40, y: 9}, {x: 50, y: 11}, {x: 60, y: 13}, {x: 100, y: 13}]
    }
  ];

  private seriesFemale = [
    {
      name: "90-boundary",
      type: "area",
      data: [{x: 20, y: 40}, {x: 30, y: 42}, {x: 40, y: 44}, {x: 50, y: 46}, {x: 60, y: 48}, {x: 100, y: 48}]
    },
    {
      name: "75-boundary",
      type: "area",
      data: [{x: 20, y: 36}, {x: 30, y: 38}, {x: 40, y: 40}, {x: 50, y: 42}, {x: 60, y: 44}, {x: 100, y: 44}]
    },
    {
      name: "50-boundary",
      type: "line",
      data: [{x: 20, y: 29}, {x: 30, y: 31}, {x: 40, y: 33}, {x: 50, y: 35}, {x: 60, y: 37}, {x: 100, y: 37}]
    },
    {
      name: "25-boundary",
      type: "area",
      data: [{x: 20, y: 20}, {x: 30, y: 22}, {x: 40, y: 24}, {x: 50, y: 26}, {x: 60, y: 28}, {x: 100, y: 28}]
    },
    {
      name: "10-boundary",
      type: "area",
      data: [{x: 20, y: 16}, {x: 30, y: 18}, {x: 40, y: 20}, {x: 50, y: 22}, {x: 60, y: 24}, {x: 100, y: 24}]
    }
  ];

  constructor(
    private dataService: DataService, 
    @Inject(LOCALE_ID) localeId: string,
    private translateService: TranslateService
  ) {
    this.series = [];

    this.chart = {
      type: "line",
      height:  200,
      zoom: { enabled: false },
      toolbar: { show: false },
      width:  (<any>window).innerWidth > 700 ? '70%' : '100%'
    };

    this.stroke = {
      curve: "smooth",
      width: [1, 1, 2, 1, 1, 2],
      dashArray: [0, 0, 0, 0, 0, 6],
      colors: [COLORS.LL_WHITE, COLORS.LL_WHITE, COLORS.LL_WHITE, COLORS.LL_WHITE, COLORS.LL_WHITE, COLORS.LL_WHITE]
    };

    this.fill = {
      type: "solid",
      opacity: [1, 1, 1, 1, 1],
      colors: ['#51B4BC', '#2F8990', '#2F8990', '#51B4BC', '#396B6F']
    }

    this.markers = {
      size: 0
    };

    this.translateService.get(['COMPONENT.BODY_MASS_GRAPH.AGE', 'COMPONENT.BODY_MASS_GRAPH.FAT_PERCENTAGE']).subscribe((translations) => {
      this.xaxis = {
        title: {
          text: translations['COMPONENT.BODY_MASS_GRAPH.AGE'],
          style: {
              color: COLORS.LL_WHITE,
              fontSize: '10px',
              fontFamily: 'ObjectSans'
          },
        },
        type: 'numeric',
        min: 20,
        max: 100,
        tickAmount: 8,

        labels: {
          trim: false,
          style: {
            colors: COLORS.LL_WHITE,
            fontSize: (<any>window).innerWidth > 700 ? '8px' : '6px',
            fontFamily: 'ObjectSans'
          },
          offsetY: (<any>window).innerWidth > 700 ? -3 : -6,
          formatter: (value) => String(Math.round(Number(value)))
        },
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: COLORS.LL_WHITE
        },
      };
  
      this.yaxis = {
        min: 0,
        max: this.dataService.sex == 'M' ? 40 : 60,
        tickAmount: 4,
        title: {
          text: translations['COMPONENT.BODY_MASS_GRAPH.FAT_PERCENTAGE'],
          style: {
              color: COLORS.LL_WHITE,
              fontSize: '10px',
              fontFamily: 'ObjectSans'
          },
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true,
          color: COLORS.LL_WHITE,
          offsetX: -5
        },
        labels: {
          offsetX: 0 + 5,
          style: {
            colors: [COLORS.LL_WHITE],
            fontSize: (<any>window).innerWidth > 700 ? '7px' : '5px',
            fontFamily: 'ObjectSans'
          },
          formatter: (value) => { return formatNumber(value, localeId, '1.0-1') }
        },
        tooltip: { enabled: false }
      };
    })

    this.annotations = {};

    this.grid = {
      yaxis: {
        lines: { show: false }
      }
    };

    this.tooltip = {
      enabled: false
    };

    this.legend = {
      show: false
    };
  }

  ngOnInit(): void {
    if (this.dataService.sex == 'M') {
      this.series = this.seriesMale;
    } else if (this.dataService.sex == 'F') {
      this.series = this.seriesFemale;
    }
    this.latestValue = DashboardResult.getLatestValue(this.result);
    if (this.latestValue && this.dataService.dob && this.latestValue.time && this.latestValue.doubleValue) {
      let age = Math.floor((this.latestValue.time - this.dataService.dob) / ( 1000 * 60 * 60 * 24 * 365));

      this.annotations = {
        points: [
          {
            x: age,
            y: this.latestValue.doubleValue,
            marker: {
              fillColor: COLORS.LL_WHITE,
              strokeColor: COLORS.LL_WHITE,
              size: 2
            }
          }
        ]
      };

      this.series.push({
        name: "indicator",
        type: "line",
        data: [{x: age, y: 0}, {x: age, y: this.latestValue.doubleValue}]
      })
    }
  }
}
