import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResultGroup, DashboardResultRiskLevel } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';

@Component({
  selector: 'll-age-and-score-evolution-page',
  templateUrl: './age-and-score-evolution-page.component.html',
  styleUrls: [
    './../layout.scss',
    './age-and-score-evolution-page.component.scss'
  ]
})
export class AgeAndScoreEvolutionPageComponent extends BaseComponent implements OnInit {

  DashboardResultRiskLevel = DashboardResultRiskLevel;

  selectedGroup: string;

  data: Observable<DashboardResultGroup | null> = EMPTY;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    private analytics: AnalyticsService
  ) {
    super();
    // Determine the group to select based on the route -> we have two routes pointing to the same component
    let path = this.route.snapshot.url[0].path;
    if (path == this.APP_ROUTES['AGE_EVOLUTION'].value) {
      this.selectedGroup = 'LL_AGE';
    } else {
      this.selectedGroup = 'LL_SCORE';
    }
  }

  ngOnInit(): void {
    this.generateOpenEvent();
    this.data = this.dataService.dashboardData;
  }

  public selectGroup(group: string): void {
    this.selectedGroup = group;
    // Update the url -> we have two routes pointing to the same component
    this.location.replaceState(`/${this.selectedGroup == 'LL_AGE' ? this.APP_ROUTES['AGE_EVOLUTION'].value : this.APP_ROUTES['SCORE_EVOLUTION'].value}`)
    this.generateOpenEvent();
  }

  private generateOpenEvent() {
    this.analytics.sendEvent(new Event(
      this.selectedGroup == 'LL_AGE' ? 'open-dashboard-age' : 'open-dashboard-score',
      'view-results',
      'dashboard',
      new Map([

      ])
    ));
  }

}
