import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DashboardResultValue } from '../model/DashboardResult';

/**
 * Pipe to extract the value of a dashboard result object.
 */
@Pipe({
  name: 'extractResultValue'
})
export class ExtractResultValuePipe implements PipeTransform {

  /**
   * String representing the current configured locale.
   */
  private localeId: string

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.localeId = localeId;
  }

  /**
   * Extract the value from a dashboard result object.
   * @param value representing the dashboard result
   * @returns a value
   */
  transform(value: DashboardResultValue | null): string | null {
    return value ? DashboardResultValue.extractValue(value, this.localeId) : null;
  }

}
