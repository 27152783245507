import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { BaseComponent } from 'src/app/components/BaseComponent';
import { DashboardResultGroup } from 'src/app/model/DashboardResult';
import { DataService } from 'src/app/services/data/data.service';
import { GroupService } from 'src/app/services/group-service/group.service';
import { AnalyticsService, Event } from 'src/app/services/host-communication/analytics.service';
import { getDashboardData } from 'src/app/utility/dataHelpers';

@Component({
  selector: 'll-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: [
    './../layout.scss',
    './category-page.component.scss'
  ]
})
export class CategoryPageComponent extends BaseComponent implements OnInit {
  @ViewChild('scrollableElement', { static: false }) scrollableElement!: ElementRef;

  public category: string | null = null;
  public categoryGroup: DashboardResultGroup | null = null;
  public selectedGroup: DashboardResultGroup | null = null;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private analytics: AnalyticsService,
    private groupService: GroupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.route.params.subscribe((params) => {
      this.category = params[this.APP_ROUTES['CATEGORY']['params'][0]];
      this.analytics.sendEvent(new Event(
        'open-dashboard-category',
        'view-results',
        'dashboard',
        new Map([
          ['category', this.category ?? 'N/A']
        ])
      ));
      
      getDashboardData(this.dataService, this.groupService, this.category).subscribe((dashboardDataResult) => {
        this.categoryGroup = dashboardDataResult.categoryGroup;
        this.selectedGroup = dashboardDataResult.selectedGroup;
      }); 
    }));
  }

  ngAfterViewInit(): void {
    const selectedGroup$ = of(this.selectedGroup);
    selectedGroup$.subscribe(() => {
      this.scrollableElement.nativeElement.scrollTop = this.groupService.selectedGroupScrollPosition;
    });
  }

  override ngOnDestroy(): void {
    this.groupService.selectGroupScrollPosition(this.scrollableElement.nativeElement.scrollTop);
  }

  public selectGroup(group: DashboardResultGroup): void {
    this.selectedGroup = group;
    this.groupService.selectGroup(group);
    this.groupService.resetSelectedGroupScrollPosition();
    
    this.analytics.sendEvent(new Event(
      'open-dashboard-category-grp',
      'view-results',
      'dashboard',
      new Map([
        ['category', this.category ?? 'N/A'],
        ['group', group.tag.toLowerCase()]
      ])
    ));
  }
}